<template>
  <div>
    <div class="root-folder">
      <div class="folder-name" :class="{ selected: isSelected('') }">
        <div class="menu">
          <a @click="addFolder" title="Add Folder"><i class="fa fa-plus"></i></a>
        </div>
        <div @click="selectFolder('')">
          <i class="fa fa-home"></i> Results
        </div>
      </div>
      <div v-if="selectedHomeFolder"  class="children">
        <Folder :folder="folder" :folders="folders" :id="folderId" :key="folderId" :selectedFolderId="selectedFolderId"
          v-for="(folder, folderId) in homeFolders"
          @add-folder="addFolder"
          @delete-folder="trashFolder"
          @select-folder="selectFolder"/>
      </div>
    </div>
    <div class="root-folder" v-if="shared.length > 0">
      <div class="folder-name shared" :class="{ selected: isSelected('shared') }">
        <div @click="selectFolder('shared')" >
          <i class="fa fa-users"></i> Shared
        </div>
      </div>
      <div v-if="selectedSharedFolder" class="children">
        <User :user="user" :key="user.email" :selectedFolderId="selectedFolderId" :isShared="true"
          v-for="user in shared"
          @select-folder="selectFolder"/>
      </div>
    </div>    
    <div class="root-folder" v-if="linked.length > 0">
      <div class="folder-name linked" :class="{ selected: isSelected('linked') }">
        <div @click="selectFolder('linked')" >
          <i class="fa fa-user-secret"></i> Linked <span class="admin">ADMIN</span>
        </div>
      </div>
      <div v-if="selectedLinkedFolder" class="children">
        <User :user="user" :key="user.email" :selectedFolderId="selectedFolderId" :isLinked="true"
          v-for="user in linked"
          @select-folder="selectFolder"/>
      </div>
    </div>    
    <div v-if="false" class="root-folder">
      <div class="folder-name vouchers" :class="{ selected: isSelected('vouchers') }">
        <div @click="selectFolder('vouchers')" >
          <i class="fa fa-ticket"></i> Vouchers
        </div>
      </div>
      <div v-if="selectedVouchers" class="children">
        <Folder :folder="folder" :folders="folders" :id="folderId" :key="folderId" :selectedFolderId="selectedFolderId" :isVoucher="true"
          v-for="(folder, folderId) in trashFolders"
          @select-folder="selectFolder"/>
      </div>
    </div>    

    <div class="root-folder">
      <div class="folder-name trash" :class="{ selected: isSelected('trash') }">
        <div class="menu">
          <a @click="emptyTrash" title="Empty Trash"><i class="fa fa-remove"></i></a>
        </div>
        <div @click="selectFolder('trash')" >
          <i class="fa fa-trash"></i> Trash
        </div>
      </div>
      <div v-if="selectedTrashFolder" class="children">
        <Folder :folder="folder" :folders="folders" :id="folderId" :key="folderId" :selectedFolderId="selectedFolderId"
          v-for="(folder, folderId) in trashFolders"
          @add-folder="addFolder"
          @delete-folder="deleteFolder"
          @select-folder="selectFolder"/>
      </div>
    </div>
  </div>
</template>

<script>
import firebase from "../../../firebase";
import Folder from './Folder.vue'
import User from './User.vue'

const firestore = firebase.firestore();

export default {
  name: 'Tree',
  components: {
    Folder,
    User,
  },
  data: () => {
    return {
      selectedHomeFolder: true,
      selectedTrashFolder: false,
      selectedSharedFolder: true,
      selectedLinkedFolder: true,
      selectedVouchers: true,
      shared: [],
      linked: [],
      vouchers: []
    }
  },
  props: {
    user: Object,
    folders: Object,
    selectedFolderId: String,
  },
  computed: {
    homeFolders: function () {
      if (!this.folders) return []

      let children = {}
      let folders = this.folders
      Object.keys(folders).forEach(function(folderId) {
          let folder = folders[folderId]
          if (folder.parent === '') {
            children[folderId] = folder
          }
      });
      return children
    },
    trashFolders: function () {
      if (!this.folders) return []

      let children = {}
      let folders = this.folders
      Object.keys(folders).forEach(function(folderId) {
          let folder = folders[folderId]
          if (folder.parent === 'trash') {
            children[folderId] = folder
          }
      });
      return children
    }
  },
  mounted() {
      this.loadData();
  },
  methods: {
    loadData() {      
      firestore
        .collection("users")
        .where("shared", "==", true)
        .where("company", "==", this.user.company)
        .onSnapshot((querySnapshot) => {
          this.shared = [];
          querySnapshot.forEach((doc) => {
            if (doc.id === this.user.email) {
              return;
            }
            this.shared.push(doc.data());
          });
      });
      if (this.user.admin) {
        firestore
          .collection("users")
          .where("linked", "==", true)
          .onSnapshot((querySnapshot) => {
            this.linked = [];
            querySnapshot.forEach((doc) => {
              if (doc.id === this.user.email) {
                return;
              }
              this.linked.push(doc.data());
            });
        });
      }
    },    
    isSelected(selectedFolderId) {
      return this.selectedFolderId === selectedFolderId;
    },
    selectFolder(selectedFolderId, folderName, isTrash, isShared, isLinked, isVoucher, email, company) {
      if (selectedFolderId === '') {
        this.selectedHomeFolder = !this.selectedHomeFolder
        this.$emit('select-folder', '', '', false)
        return
      }

      if (selectedFolderId === 'trash') {
        this.selectedTrashFolder = !this.selectedTrashFolder
        this.$emit('select-folder', 'trash', '', true, false, false, false, email, company)
        return
      }

      if (selectedFolderId === 'shared') {
        this.selectedSharedFolder = !this.selectedSharedFolder
        this.$emit('select-folder', 'shared', '', false, true, false, false, email, company)
        return
      }

      if (selectedFolderId === 'linked') {
        this.selectedLinkedFolder = !this.selectedLinkedFolder
        this.$emit('select-folder', 'linked', '', false, false, true, false, email, company)
        return
      }

      if (selectedFolderId === 'voucher') {
        this.selectedVoucherFolder = !this.selectedVoucherFolder
        this.$emit('select-folder', 'voucher', '', false, false, false, true, email, company)
        return
      }

      this.$emit('select-folder', selectedFolderId, folderName, isTrash, isShared, isLinked, isVoucher, email, company);
    },
    addFolder() {
      this.$emit('add-folder');
    },
    deleteFolder() {
      this.$emit('delete-folder');
    },
    trashFolder() {
      this.$emit('trash-folder');
    },
    emptyTrash() {
      this.$emit('empty-trash');
    }
  }
}
</script>

<style scoped>
  button {
    float: right;
    padding: 12px 16px;
    margin-top: -8px;
    color: white;
    background-color: #61ae24;
    font-size: 14px;
    border-radius: 2px;
    border: none;
    cursor: pointer;
  }

  /* .trash {
    margin-top: 16px;
  } */

  .trash + button {
    display: none;
  }

  .trash.selected + button {
    display: inline-block;
  }

  .selected {
     color: yellow !important;
   }

  .selected  .menu {
    display: inline-block;
  }

  .children {
    margin-left: 28px;
  }

  .root-folder {
    margin-bottom: 8px;
  }

  .folder-name {
    margin-bottom: 8px;
    padding-bottom: 8px;
    cursor: pointer;
  }

  .folder-name:hover {
    color: white;
  }

  .folder-name i {
    width: 24px;
  }

  .menu {
    display: none;
    float: right;
    margin-left: 16px;
    margin-right: 0px;
    margin-top: -4px;
    padding: 2px 6px;
    color: white;
    background-color: #3e3e3e;
    font-size: 14px;
    border-radius: 2px;
    border: none;
    cursor: pointer;
    -webkit-box-shadow: 0px 0px 2px 0px rgba(0,0,0,0.2);
    -moz-box-shadow: 0px 0px 2px 0px rgba(0,0,0,0.2);
    box-shadow: 0px 0px 2px 0px rgba(0,0,0,0.2);
  }

  .menu a:first-child {
    padding-left: 0px;
    margin-left: 4px;
    border-left: none;
    font-size: 16px;
  }

  .menu a {
    padding-left: 12px;
    margin-left: 8px;
    border-left: 1px solid #5e5e5e;
    font-size: 16px;
  }

  .admin {
    display: inline-block;
    margin-left: 4px;    
    text-align: center;
    vertical-align: middle;
    width: 46px;
    height: 13px;
    line-height: 13px;
    background: transparent;
    color: #303030;
    border: none;
    font-size: 10px;
    font-weight: bold;
    cursor: pointer;
    background: yellow;
    border-radius: 4px;
  }

</style>
