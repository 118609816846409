<template>
  <div class="container">
    <Profile v-if="showProfile" :toggleProfile="toggleProfile" :user="user"/>
    <button v-if="user.admin" class="admin" v-on:click="window.location.href = '/admin/admin.html'">ADMIN</button>
    <div class="version">version 1.0.14 (rep)</div>
    <div class="menu">
      <div class="menu-left">
        <img src="../../assets/img/mmlogo.svg" />
      </div>
      <div class="menu-right">
        <a class="button right" v-on:click="logout"><span>Logout </span><i class="fa fa-sign-out"></i></a>
        <a class="button right" v-if="false" v-on:click="toggleProfile"><i class="fa fa-cog"></i></a>
        <span class="separator"></span>
        <a v-if="!isShared && !isLinked" class="button nomargin green fa-spin-hover" @click="startLPCAT"><span>Run LPCAT </span><i class="fa fa-rocket fa-spin-hover"></i></a>
        <span v-if="!isShared && !isLinked" class="separator"></span>
        <div class="search">
          <input type="text" placeholder="search ..." v-model="searchText" autofocus
            v-on:keyup.enter="searchResults"
            v-on:keyup.esc="clearSearch"
          />
          <a @click="searchResults"><i class="fa fa-search"></i></a>
        </div>
        <a v-if="!isShared && !isLinked" class="button" v-on:click="toggleUpload"><span>Upload </span><i class="fa fa-upload"></i></a>
        <a v-if="!isShared && !isLinked" class="button" v-on:click="deleteButton"><span>Delete </span><i class="fa fa-trash"></i></a>
        <span v-if="!isShared && !isLinked" class="separator"></span>
        <a class="button"><span>Runs</span> <i class="fa fa-star green"></i> {{runs}} </a>
      </div>
    </div>
    <div class="inner-container">
      <Tree class="tree"
        :user="user"
        :folders="folders"
        :selectedFolderId="selectedFolderId"
        @add-folder="addFolder"
        @delete-folder="deleteFolder"
        @trash-folder="trashFolder"
        @select-folder="selectFolder"
        @empty-trash="emptyTrash"
      />
      <Results class="results"
        :results="results"
        :selectedResultId="selectedResultId"
        :selectedFolderName="selectedFolderName"
        :sort="sort"
        :asc="asc"
        :isTrash="isTrash"
        :isShared="isShared"
        :isLinked="isLinked"
        :isVoucher="isVoucher"
        :searchInput="searchInput"
        :user="user"
        @empty-trash="emptyTrash"
        @delete-result="deleteResult"
        @move-result-to-trash="moveResultToTrash"
        @select-result="selectResult"
        @view-result="viewResult"
        @start-lpcat="startLPCAT"
        @sort="sortColumn"
        @search="searchResults"
        @clear-search="clearSearch"
      />
    </div>
    <Upload v-if="isUploadVisible"
      :user="user"
      :selectedFolderId="selectedFolderId"
      :toggleUpload="toggleUpload"
      :importLegacyResults="importLegacyResults"/>
  </div>
</template>

<script>

import Tree from './tree/Tree.vue'
import Results from './results/Results.vue'
import Upload from './upload/Upload.vue'
import Profile from '../profile/Profile.vue'

export default {
  name: 'Portal',
  components: {
    Tree,
    Results,
    Upload,
    Profile
  },
  props: {
    user: Object,
    otherUser: Object,
    folders: Object,
    results: Object,
    runs: Number,
    selectedFolderId: String,
    selectedResultId: String,
    selectedFolderName: String,
    searchInput: String,
    sort: String,
    asc: Boolean,
    isTrash: Boolean,
    isShared: Boolean,
    isLinked: Boolean,
    isVoucher: Boolean,
    importLegacyResults: Function
  },
  data () {
    return {
      searchText: '',
      isUploadVisible: false,
      showProfile: false,
    }
  },
  computed: {
    window: () => window
  },
  methods: {
    startLPCAT() {
      this.$emit('start-lpcat')
    },
    addFolder() {
      this.$emit('add-folder')
    },
    deleteFolder() {
      this.$emit('delete-folder')
    },
    trashFolder() {
      this.$emit('trash-folder')
    },
    emptyTrash() {
      this.$emit('empty-trash')
    },
    selectFolder(selectedFolderId, selectedFolderName, isTrash, isShared, isLinked, isVoucher, email, company) {
      this.$emit('select-folder', selectedFolderId, selectedFolderName, isTrash, isShared, isLinked, isVoucher, email, company)
    },
    addResult() {
      this.$emit('add-result')
    },
    deleteResult(selectedResultId) {
      this.$emit('delete-result', selectedResultId)
    },
    moveResultToTrash(selectedResultId) {
      this.$emit('move-result-to-trash', selectedResultId)
    },
    selectResult(selectedResultId) {
      this.$emit('select-result', selectedResultId)
    },
    viewResult() {
      this.$emit('view-result')
    },
    sortColumn(sort, asc) {
      this.$emit('sort', sort, asc)
    },
    searchResults() {
      this.$emit('search', this.searchText)
    },
    clearSearch() {
      this.searchText = ''
      this.$emit('search', this.searchText)
    },
    logout() {
      this.$emit('log-user-out')
    },
    toggleAdmin() {
      this.$emit('toggle-admin')
    },
    toggleUpload() {
      this.isUploadVisible = !this.isUploadVisible
      this.uploadStatus = []
    },
    toggleProfile() {
      this.showProfile = !this.showProfile
    },
    deleteButton() {
      if (!this.selectedResultId) {
        alert('Select a result to delete.')
        return
      }
      if (this.isTrash) {
        this.deleteResult(this.selectedResultId)
      } else {
        this.moveResultToTrash(this.selectedResultId)
      }
    }
  }
}
</script>

<style scoped>
  .container {
    position: relative;
    display: flex;
    flex-direction: column;
    font: 16px/24px "Helvetica Neue", Arial, Helvetica, Geneva, sans-serif;
    height: 100%;
  }

  .inner-container {
    display: flex;
    flex-direction: row;
    flex: 1;
    background-color: #303030;
  }

  .admin {
    position: absolute;
    left: 232px;
    top: 38px;
    background: transparent;
    color: #303030;
    border: none;
    font-size: 10px;
    font-weight: bold;
    cursor: pointer;
    background: yellow;
    border-radius: 4px;
  }

  .menu {
      display: flex;
  }

  .menu-left {
    flex: 0.3;
    flex-direction: row;
    background-color: #343434;
    padding: 24px 16px;
    color: white;
  }

  .menu-right {
    flex: 1;
    background-color: #343434;
    padding: 24px 16px;
    color: white;
  }

  .menu img {
    float: left;
    vertical-align: middle;
    height: 26px;
    margin-top: 2px;
  }

  .separator {
    float: left;
    margin-left: 8px;
    margin-right: -8px;
    border-left: 1px solid #5e5e5e;
    width: 10px;
    height: 30px;
  }

  .right + .separator {
    float: right;
  }

  .button {
    float: left;
    display: block;
    margin-left: 8px;
    margin-right: 0px;
    margin-top:-4px;
    margin-bottom: -5px;
    padding: 8px 12px;
    min-width: 32px;
    color: white;
    background-color: #3e3e3e;
    font-size: 14px;
    border-radius: 2px;
    border: none;
    cursor: pointer;
    -webkit-box-shadow: 0px 0px 2px 0px rgba(0,0,0,0.2);
    -moz-box-shadow: 0px 0px 2px 0px rgba(0,0,0,0.2);
    box-shadow: 0px 0px 2px 0px rgba(0,0,0,0.2);
  }

  .button span + i {
    padding-left: 12px;
    margin-left: 8px;
    border-left: 1px solid #5e5e5e;
    font-size: 16px;
    color: white;
  }

  .button span + i.green {
    color: #61ae24;
  }

  .button a span {
    font-size: 10px;
    margin-left: 4px;
  }


  .button:hover {
    background-color: #4e4e4e;
  }

  .button.right {
    float: right;
  }

  .button.nomargin {
    margin-left: 0px;
  }

  .tree {
    flex: 0.3;
    flex-direction: row;
    padding: 16px;
    color: #c3c3c3;
    background: #303030;
    overflow: auto;
  }

  .results {
    flex: 1;
    background-color: white;
    -webkit-box-shadow: inset 2px 2px 3px 0px rgba(0,0,0,0.1);
    -moz-box-shadow: inset 2px 2px 3px 0px rgba(0,0,0,0.1);
    box-shadow: inset 2px 2px 3px 0px rgba(0,0,0,0.1);
    overflow: hidden;
    border-top-left-radius: 4px;
    display: flex;
    flex-direction: column;
    background-color: #fafafa !important;
  }

  .green {
    color: #61ae24;
  }

  .button.green {
    color: white;
    background-color: #61ae24;
  }

  .button.green:hover {
    background-color: #87D945;
  }

  .button.green i {
    border-left: 1px solid #BEEA99;
  }

  .search {
    float: right;
    width: auto;
    margin-top:-4px;
    margin-bottom: -5px;
    padding: 0px 12px 0px 0px;
    color: white;
    background-color: #4e4e4e;
    font-size: 14px;
    border-radius: 2px;
    border: none;
    cursor: pointer;
    -webkit-box-shadow: 0px 0px 2px 0px rgba(0,0,0,0.2);
    -moz-box-shadow: 0px 0px 2px 0px rgba(0,0,0,0.2);
    box-shadow: 0px 0px 2px 0px rgba(0,0,0,0.2);
  }

  .search input {
    background-color: transparent;
    border: none;
    outline: none;
    font-size: 16px;
    color: white;
    width: 200px;
    height: 40px;
    padding-left: 12px;
  }

  .search input::placeholder {
    color: #ADADAD;
  }

  .search i {
    padding-left: 12px;
    margin-left: 8px;
    border-left: 1px solid #6e6e6e;
    font-size: 16px;
  }

  .search a:hover {
    color: yellow;
    font-weight: bold;
  }

  .search:hover {
    background-color: #5e5e5e;
  }

  @media only screen and (max-width: 970px) {
    .search {
      margin-right: 8px;
    }

    .button, .runs {
      margin-left: 8px;
    }

    .button span, .runs span, .lpcat span {
      display: none;
    }

    .button i, .runs i, .lpcat i {
      margin-left: 0px;
      padding-left: 0px;
      border-left: none;
    }
  }

  @media only screen and (min-width: 970px) and (max-width: 1100px) {
    .button, .runs {
      margin-left: 8px;
    }

    .button span, .runs span {
      display: none;
    }

    .button i, .runs i {
      margin-left: 0px;
      padding-left: 0px;
      border-left: none;
    }
  }

  @media only screen and (min-width: 1101px) and (max-width: 1340px) {
    .button, .runs {
      margin-left: 8px;
    }

    .button span {
      /* display: none; */
    }

    .button i {
      margin-left: 0px;
      padding-left: 0px;
      border-left: none;
    }
  }

  .version {
    position: absolute;
    left: 16px;
    top: 52px;
    font-size: 12px;
    color: gray;
  }
</style>
