<template>
  <div class="container">

    <video autoplay loop muted poster="../../assets/img/portal/lawn.jpg">
      <source src="../../assets/img/portal/lawn.mp4" type="video/mp4">
    </video>

    <div class="login">
      <img class="header" src="../../assets/img/mmlogo.svg" />
      <h1 class="title">User Login</h1>
      <div class="label">E-mail</div>
      <input type="text" v-model="email"/>
      <div class="label">Password</div>
      <input type="password" v-model="password" autocapitalize="off"/>
      <button v-on:click="login">Sign In</button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Login',
  data() {
    return {
      email: '',
      password: ''
    }
  },
  methods: {
    login() {
      this.$emit('log-in-user', this.email, this.password);
    },
  }
}
</script>

<style scoped>
  .container {
    width: 100%;
    height: 100%;
    overflow: hidden;
    background: url(../../assets/img/portal/lawn.jpg) no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;

    color: #c3c3c3;
    font: 16px/24px "Helvetica Neue", Arial, Helvetica, Geneva, sans-serif;
    text-shadow: 0 1px 2px rgba(0, 0, 0, 0.8);
    font-weight: 400;
  }

  video {
    width: 100%;
    min-height: 100%;
    object-fit: cover;
    padding: 0px;
    margin: 0px;
  }

  input {
    width: 100%;
    padding: 12px;
    margin-top: 8px;
    border-radius: 4px;
    border: 1px solid #888;
    font-weight: bolder;
    font: 18px "Helvetica Neue", Arial, Helvetica, Geneva, sans-serif;
    color: #3e3e3e;
  }

  input[type='password'] {
    letter-spacing: 2px;
  }

  button {
    position: absolute;
    padding: 12px 16px;
    bottom: 40px;
    right: 40px;
    color: white;
    background-color: #61ae24;
    font-size: 14px;
    border-radius: 4px;
    border: none;
    cursor: pointer;
  }

  .header {
      margin-bottom: 12px;
  }

  .title {
    font-size: 18px;
    padding-top: 12px;
    margin-bottom: 16px;
  }

  .label {
    margin-top: 12px;
  }

  .login {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 400px;
    height: 400px;
    padding: 40px;
    background-color: #3e3e3e;
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.15) 0px 30px 40px 10px;
  }
</style>
