<template>
    <div class="lpcat">
        <Loader :isLoading="!loadedTranslations || !lpcatApisWarmedUp" />

        <div class="language">            
            <div v-for="language in publishedLanguages" :key="language" v-bind:class="{ selected: isLanguage(language) }" @click="() => setLanguage(language)">{{language}}</div>

            <template v-if="isAdmin && unpublishedLanguages.length > 0">
                <span>UNPUBLISHED</span>                
                <div v-for="language in unpublishedLanguages" :key="language" v-bind:class="{ selected: isLanguage(language) }" @click="() => setLanguage(language)">{{language}}</div>
            </template>
        </div>

        <!-- Start page -->
        <div class="container" v-if="showStart">
            <div class="nobuttons intro video">
                <video id="video" ref="video" preload="auto" autoplay="true" loop="loop" muted="muted" volume="0" src="../../assets/img/video.mp4" poster="../../assets/img/video.jpg"></video>
                <div class="video-corner1"></div>
                <div class="video-corner2"></div>
                <div class="lpcatlogo" @click="gotoPortal()"></div>
                <div class="start"><a href="#" v-on:click="next()">{{screens.startTest}}</a></div>
                <div class="version">v3</div>
                <div class="mmlogo"></div>
            </div>
        </div>

        <!-- Demographics 1 -->
        <div class="container" v-if="showDemographics">
            <div class="bubble left nextArrow textPage">
                {{screens.clickArrowContent}}
            </div>
            <div class="page">
                <h2>{{screens.demogr1testtakerinfo.header}}</h2>
                <p v-if="screens.demogr1testtakerinfo.paragraph1" v-html="screens.demogr1testtakerinfo.paragraph1"></p>
                <p v-if="screens.demogr1testtakerinfo.paragraph2" v-html="screens.demogr1testtakerinfo.paragraph2"></p>
                <p v-if="screens.demogr1testtakerinfo.paragraph3" v-html="screens.demogr1testtakerinfo.paragraph3"></p>
                <div class="form">
                    <div class="field">
                        <label for="name">{{screens.firstName}} *</label>
                        <input v-model="testData.firstname" type="text" name="name" :placeholder="screens.firstNamePlaceholder" autocomplete="off">
                        <div class="error" v-if="showFirstNameError">{{screens.firstNameError}}</div>
                    </div>
                    <div class="field">
                        <label for="surname">{{screens.lastName}} *</label>
                        <input v-model="testData.lastname" type="text" name="surname" :placeholder="screens.lastNamePlaceholder" autocomplete="off">
                        <div class="error" v-if="showLastNameError">{{screens.lastNameError}}</div>
                    </div>
                    <div class="field">
                        <label for="age">{{screens.age}} *</label>
                        <input v-model="testData.age" type="number" name="age" :placeholder="screens.agePlaceholder" autocomplete="off">
                        <div class="error" v-if="showAgeError">{{screens.ageError}}</div>
                    </div>
                    <div class="field">
                        <label for="gender">{{screens.gender}} *</label>
                        <select v-model="testData.gender" v-bind:class="{ placeholder: !testData.gender }" name="gender" :placeholder="screens.genderPlaceholder">
                            <option value="" disabled hidden>{{screens.genderPlaceholder}}</option>
                            <option value="Male">{{screens.genderMale}}</option>
                            <option value="Female">{{screens.genderFemale}}</option>
                            <option value="Other">{{screens.genderOther}}</option>
                        </select>
                        <div class="error" v-if="showGenderError">{{screens.genderError}}</div>
                    </div>
                    <div class="field">
                        <label for="educationLevel">{{screens.education}} *</label>
                        <select v-model="testData.educationLevel" v-bind:class="{ placeholder: !testData.educationLevel }" name="educationLevel">
                            <option value="" disabled hidden>{{screens.educationPlaceholder}}</option>
                            <option value="No formal schooling">{{screens.educationNone}}</option>
                            <option value="Primary school (1-7 years of education)">{{screens.educationLower}}</option>
                            <option value="Secondary school (8-12 years of education)">{{screens.educationMiddle}}</option>
                            <option value="Certificate">{{screens.educationCertification}}</option>
                            <option value="Diploma (1-3 years post school)">{{screens.educationDiploma}}</option>
                            <option value="Degree (Batchelors degree or first degree)">{{screens.educationDegree}}</option>
                            <option value="Postgraduate">{{screens.educationDoctorate}}</option>
                        </select>
                        <div class="error" v-if="showEducationError">{{screens.educationError}}</div>
                    </div>
                    <div class="field">
                        <label for="idnumber">{{screens.personalId}}</label>
                        <input v-model="testData.idnumber" type="text" name="idnumber" :placeholder="screens.personalIdPlaceholder" autocomplete="off">
                    </div>
                </div>
                <div class="prev" v-on:click="previous()"></div>
                <div class="next" v-on:click="next()"></div>
            </div>
        </div>

        <!-- Demographics 2 -->
        <div class="container" v-if="showOptionals">
            <div class="page">
                <div class="bubble left nextArrow textPage">
                    {{screens.clickArrowContent}}
                </div>
                <h2>{{screens.demogr2researchinfo.header}}</h2>
                <p v-if="screens.demogr2researchinfo.paragraph1" v-html="screens.demogr2researchinfo.paragraph1"></p>
                <p v-if="screens.demogr2researchinfo.paragraph2" v-html="screens.demogr2researchinfo.paragraph2"></p>
                <p v-if="screens.demogr2researchinfo.paragraph3" v-html="screens.demogr2researchinfo.paragraph3"></p>
				<div class="form">
					<div class="field">
						<label for="country">{{screens.country}}</label>
						<select v-if="!isLanguage('NL')" v-model="testData.country" v-bind:class="{ placeholder: !testData.country }" name="country" :placeholder="language.countrySelect">
							<option value="" disabled hidden>{{screens.countrySelect}}</option>
							<option :value="language.countryNone">{{screens.countryNone}}</option>
							<option value="Afghanistan">Afghanistan</option>
							<option value="Åland Islands">Åland Islands</option>
							<option value="Albania">Albania</option>
							<option value="Algeria">Algeria</option>
							<option value="American Samoa">American Samoa</option>
							<option value="Andorra">Andorra</option>
							<option value="Angola">Angola</option>
							<option value="Anguilla">Anguilla</option>
							<option value="Antarctica">Antarctica</option>
							<option value="Antigua and Barbuda">Antigua and Barbuda</option>
							<option value="Argentina">Argentina</option>
							<option value="Armenia">Armenia</option>
							<option value="Aruba">Aruba</option>
							<option value="Australia">Australia</option>
							<option value="Austria">Austria</option>
							<option value="Azerbaijan">Azerbaijan</option>
							<option value="Bahamas">Bahamas</option>
							<option value="Bahrain">Bahrain</option>
							<option value="Bangladesh">Bangladesh</option>
							<option value="Barbados">Barbados</option>
							<option value="Belarus">Belarus</option>
							<option value="Belgium">Belgium</option>
							<option value="Belize">Belize</option>
							<option value="Benin">Benin</option>
							<option value="Bermuda">Bermuda</option>
							<option value="Bhutan">Bhutan</option>
							<option value="Bolivia">Bolivia</option>
							<option value="Bosnia and Herzegovina">Bosnia and Herzegovina</option>
							<option value="Botswana">Botswana</option>
							<option value="Bouvet Island">Bouvet Island</option>
							<option value="Brazil">Brazil</option>
							<option value="British Indian Ocean Territory">British Indian Ocean Territory</option>
							<option value="Brunei Darussalam">Brunei Darussalam</option>
							<option value="Bulgaria">Bulgaria</option>
							<option value="Burkina Faso">Burkina Faso</option>
							<option value="Burundi">Burundi</option>
							<option value="Cambodia">Cambodia</option>
							<option value="Cameroon">Cameroon</option>
							<option value="Canada">Canada</option>
							<option value="Cape Verde">Cape Verde</option>
							<option value="Cayman Islands">Cayman Islands</option>
							<option value="Central African Republic">Central African Republic</option>
							<option value="Chad">Chad</option>
							<option value="Chile">Chile</option>
							<option value="China">China</option>
							<option value="Christmas Island">Christmas Island</option>
							<option value="Cocos (Keeling) Islands">Cocos (Keeling) Islands</option>
							<option value="Colombia">Colombia</option>
							<option value="Comoros">Comoros</option>
							<option value="Congo">Congo</option>
							<option value="Congo, The Democratic Republic of The">Congo, The Democratic Republic of The</option>
							<option value="Cook Islands">Cook Islands</option>
							<option value="Costa Rica">Costa Rica</option>
							<option value="Cote D'ivoire">Cote D'ivoire</option>
							<option value="Croatia">Croatia</option>
							<option value="Cuba">Cuba</option>
							<option value="Cyprus">Cyprus</option>
							<option value="Czech Republic">Czech Republic</option>
							<option value="Denmark">Denmark</option>
							<option value="Djibouti">Djibouti</option>
							<option value="Dominica">Dominica</option>
							<option value="Dominican Republic">Dominican Republic</option>
							<option value="Ecuador">Ecuador</option>
							<option value="Egypt">Egypt</option>
							<option value="El Salvador">El Salvador</option>
							<option value="Equatorial Guinea">Equatorial Guinea</option>
							<option value="Eritrea">Eritrea</option>
							<option value="Estonia">Estonia</option>
							<option value="Ethiopia">Ethiopia</option>
							<option value="Falkland Islands (Malvinas)">Falkland Islands (Malvinas)</option>
							<option value="Faroe Islands">Faroe Islands</option>
							<option value="Fiji">Fiji</option>
							<option value="Finland">Finland</option>
							<option value="France">France</option>
							<option value="French Guiana">French Guiana</option>
							<option value="French Polynesia">French Polynesia</option>
							<option value="French Southern Territories">French Southern Territories</option>
							<option value="Gabon">Gabon</option>
							<option value="Gambia">Gambia</option>
							<option value="Georgia">Georgia</option>
							<option value="Germany">Germany</option>
							<option value="Ghana">Ghana</option>
							<option value="Gibraltar">Gibraltar</option>
							<option value="Greece">Greece</option>
							<option value="Greenland">Greenland</option>
							<option value="Grenada">Grenada</option>
							<option value="Guadeloupe">Guadeloupe</option>
							<option value="Guam">Guam</option>
							<option value="Guatemala">Guatemala</option>
							<option value="Guernsey">Guernsey</option>
							<option value="Guinea">Guinea</option>
							<option value="Guinea-bissau">Guinea-bissau</option>
							<option value="Guyana">Guyana</option>
							<option value="Haiti">Haiti</option>
							<option value="Heard Island and Mcdonald Islands">Heard Island and Mcdonald Islands</option>
							<option value="Holy See (Vatican City State)">Holy See (Vatican City State)</option>
							<option value="Honduras">Honduras</option>
							<option value="Hong Kong">Hong Kong</option>
							<option value="Hungary">Hungary</option>
							<option value="Iceland">Iceland</option>
							<option value="India">India</option>
							<option value="Indonesia">Indonesia</option>
							<option value="Iran, Islamic Republic of">Iran, Islamic Republic of</option>
							<option value="Iraq">Iraq</option>
							<option value="Ireland">Ireland</option>
							<option value="Isle of Man">Isle of Man</option>
							<option value="Israel">Israel</option>
							<option value="Italy">Italy</option>
							<option value="Jamaica">Jamaica</option>
							<option value="Japan">Japan</option>
							<option value="Jersey">Jersey</option>
							<option value="Jordan">Jordan</option>
							<option value="Kazakhstan">Kazakhstan</option>
							<option value="Kenya">Kenya</option>
							<option value="Kiribati">Kiribati</option>
							<option value="Korea, Democratic People's Republic of">Korea, Democratic People's Republic of</option>
							<option value="Korea, Republic of">Korea, Republic of</option>
							<option value="Kuwait">Kuwait</option>
							<option value="Kyrgyzstan">Kyrgyzstan</option>
							<option value="Lao People's Democratic Republic">Lao People's Democratic Republic</option>
							<option value="Latvia">Latvia</option>
							<option value="Lebanon">Lebanon</option>
							<option value="Lesotho">Lesotho</option>
							<option value="Liberia">Liberia</option>
							<option value="Libyan Arab Jamahiriya">Libyan Arab Jamahiriya</option>
							<option value="Liechtenstein">Liechtenstein</option>
							<option value="Lithuania">Lithuania</option>
							<option value="Luxembourg">Luxembourg</option>
							<option value="Macao">Macao</option>
							<option value="Macedonia, The Former Yugoslav Republic of">Macedonia, The Former Yugoslav Republic of</option>
							<option value="Madagascar">Madagascar</option>
							<option value="Malawi">Malawi</option>
							<option value="Malaysia">Malaysia</option>
							<option value="Maldives">Maldives</option>
							<option value="Mali">Mali</option>
							<option value="Malta">Malta</option>
							<option value="Marshall Islands">Marshall Islands</option>
							<option value="Martinique">Martinique</option>
							<option value="Mauritania">Mauritania</option>
							<option value="Mauritius">Mauritius</option>
							<option value="Mayotte">Mayotte</option>
							<option value="Mexico">Mexico</option>
							<option value="Micronesia, Federated States of">Micronesia, Federated States of</option>
							<option value="Moldova, Republic of">Moldova, Republic of</option>
							<option value="Monaco">Monaco</option>
							<option value="Mongolia">Mongolia</option>
							<option value="Montenegro">Montenegro</option>
							<option value="Montserrat">Montserrat</option>
							<option value="Morocco">Morocco</option>
							<option value="Mozambique">Mozambique</option>
							<option value="Myanmar">Myanmar</option>
							<option value="Namibia">Namibia</option>
							<option value="Nauru">Nauru</option>
							<option value="Nepal">Nepal</option>
							<option value="Netherlands">Netherlands</option>
							<option value="Netherlands Antilles">Netherlands Antilles</option>
							<option value="New Caledonia">New Caledonia</option>
							<option value="New Zealand">New Zealand</option>
							<option value="Nicaragua">Nicaragua</option>
							<option value="Niger">Niger</option>
							<option value="Nigeria">Nigeria</option>
							<option value="Niue">Niue</option>
							<option value="Norfolk Island">Norfolk Island</option>
							<option value="Northern Mariana Islands">Northern Mariana Islands</option>
							<option value="Norway">Norway</option>
							<option value="Oman">Oman</option>
							<option value="Pakistan">Pakistan</option>
							<option value="Palau">Palau</option>
							<option value="Palestinian Territory, Occupied">Palestinian Territory, Occupied</option>
							<option value="Panama">Panama</option>
							<option value="Papua New Guinea">Papua New Guinea</option>
							<option value="Paraguay">Paraguay</option>
							<option value="Peru">Peru</option>
							<option value="Philippines">Philippines</option>
							<option value="Pitcairn">Pitcairn</option>
							<option value="Poland">Poland</option>
							<option value="Portugal">Portugal</option>
							<option value="Puerto Rico">Puerto Rico</option>
							<option value="Qatar">Qatar</option>
							<option value="Reunion">Reunion</option>
							<option value="Romania">Romania</option>
							<option value="Russian Federation">Russian Federation</option>
							<option value="Rwanda">Rwanda</option>
							<option value="Saint Helena">Saint Helena</option>
							<option value="Saint Kitts and Nevis">Saint Kitts and Nevis</option>
							<option value="Saint Lucia">Saint Lucia</option>
							<option value="Saint Pierre and Miquelon">Saint Pierre and Miquelon</option>
							<option value="Saint Vincent and The Grenadines">Saint Vincent and The Grenadines</option>
							<option value="Samoa">Samoa</option>
							<option value="San Marino">San Marino</option>
							<option value="Sao Tome and Principe">Sao Tome and Principe</option>
							<option value="Saudi Arabia">Saudi Arabia</option>
							<option value="Senegal">Senegal</option>
							<option value="Serbia">Serbia</option>
							<option value="Seychelles">Seychelles</option>
							<option value="Sierra Leone">Sierra Leone</option>
							<option value="Singapore">Singapore</option>
							<option value="Slovakia">Slovakia</option>
							<option value="Slovenia">Slovenia</option>
							<option value="Solomon Islands">Solomon Islands</option>
							<option value="Somalia">Somalia</option>
							<option value="South Africa">South Africa</option>
							<option value="South Georgia and The South Sandwich Islands">South Georgia and The South Sandwich Islands</option>
							<option value="Spain">Spain</option>
							<option value="Sri Lanka">Sri Lanka</option>
							<option value="Sudan">Sudan</option>
							<option value="Suriname">Suriname</option>
							<option value="Svalbard and Jan Mayen">Svalbard and Jan Mayen</option>
							<option value="Swaziland">Swaziland</option>
							<option value="Sweden">Sweden</option>
							<option value="Switzerland">Switzerland</option>
							<option value="Syrian Arab Republic">Syrian Arab Republic</option>
							<option value="Taiwan, Province of China">Taiwan, Province of China</option>
							<option value="Tajikistan">Tajikistan</option>
							<option value="Tanzania, United Republic of">Tanzania, United Republic of</option>
							<option value="Thailand">Thailand</option>
							<option value="Timor-leste">Timor-leste</option>
							<option value="Togo">Togo</option>
							<option value="Tokelau">Tokelau</option>
							<option value="Tonga">Tonga</option>
							<option value="Trinidad and Tobago">Trinidad and Tobago</option>
							<option value="Tunisia">Tunisia</option>
							<option value="Turkey">Turkey</option>
							<option value="Turkmenistan">Turkmenistan</option>
							<option value="Turks and Caicos Islands">Turks and Caicos Islands</option>
							<option value="Tuvalu">Tuvalu</option>
							<option value="Uganda">Uganda</option>
							<option value="Ukraine">Ukraine</option>
							<option value="United Arab Emirates">United Arab Emirates</option>
							<option value="United Kingdom">United Kingdom</option>
							<option value="United States">United States</option>
							<option value="United States Minor Outlying Islands">United States Minor Outlying Islands</option>
							<option value="Uruguay">Uruguay</option>
							<option value="Uzbekistan">Uzbekistan</option>
							<option value="Vanuatu">Vanuatu</option>
							<option value="Venezuela">Venezuela</option>
							<option value="Viet Nam">Viet Nam</option>
							<option value="Virgin Islands, British">Virgin Islands, British</option>
							<option value="Virgin Islands, U.S.">Virgin Islands, U.S.</option>
							<option value="Wallis and Futuna">Wallis and Futuna</option>
							<option value="Western Sahara">Western Sahara</option>
							<option value="Yemen">Yemen</option>
							<option value="Zambia">Zambia</option>
							<option value="Zimbabwe">Zimbabwe</option>
						</select>
						<select v-if="isLanguage('NL')" v-model="testData.country" v-bind:class="{ placeholder: !testData.country }" name="country" :placeholder="language.countrySelect">
							<option value="" disabled hidden>{{screens.countrySelect}}</option>
							<option :value="language.countryNone">{{screens.countryNone}}</option>
							<option value="Afghanistan">Afghanistan</option>
							<option value="Aland-eilanden">Aland-eilanden</option>
							<option value="Albanië">Albanië</option>
							<option value="Algerije">Algerije</option>
							<option value="Amerikaans Samoa">Amerikaans Samoa</option>
							<option value="Andorra">Andorra</option>
							<option value="Angola">Angola</option>
							<option value="Anguilla">Anguilla</option>
							<option value="Antarctica">Antarctica</option>
							<option value="Antigua en Barbuda">Antigua en Barbuda</option>
							<option value="Argentinië">Argentinië</option>
							<option value="Armenië">Armenië</option>
							<option value="Aruba">Aruba</option>
							<option value="Australië">Australië</option>
							<option value="Oostenrijk">Oostenrijk</option>
							<option value="Azerbeidzjan">Azerbeidzjan</option>
							<option value="Bahamas">Bahamas</option>
							<option value="Bahrein">Bahrein</option>
							<option value="Bangladesh">Bangladesh</option>
							<option value="Barbados">Barbados</option>
							<option value="Wit-Rusland">Wit-Rusland</option>
							<option value="Belgie">Belgie</option>
							<option value="Belize">Belize</option>
							<option value="Benin">Benin</option>
							<option value="Bermuda">Bermuda</option>
							<option value="Bhutan">Bhutan</option>
							<option value="Bolivia">Bolivia</option>
							<option value="Bonaire, Sint Eustatius en Saba">Bonaire, Sint Eustatius en Saba</option>
							<option value="Bosnië-Herzegovina">Bosnië-Herzegovina</option>
							<option value="Botswana">Botswana</option>
							<option value="Bouvet-eiland">Bouvet-eiland</option>
							<option value="Brazilië">Brazilië</option>
							<option value="Brits-Indisch oceaan gebied">Brits-Indisch oceaan gebied</option>
							<option value="Brunei Darussalam">Brunei Darussalam</option>
							<option value="Bulgarije">Bulgarije</option>
							<option value="Burkina Faso">Burkina Faso</option>
							<option value="Burundi">Burundi</option>
							<option value="Cambodja">Cambodja</option>
							<option value="Kameroen">Kameroen</option>
							<option value="Canada">Canada</option>
							<option value="Kaapverdië">Kaapverdië</option>
							<option value="Kaaiman Eilanden">Kaaiman Eilanden</option>
							<option value="Centraal Afrikaanse Republiek">Centraal Afrikaanse Republiek</option>
							<option value="Tsjaad">Tsjaad</option>
							<option value="Chili">Chili</option>
							<option value="China">China</option>
							<option value="Kersteiland">Kersteiland</option>
							<option value="Cocos (Keeling) eilanden">Cocos (Keeling) eilanden</option>
							<option value="Colombia">Colombia</option>
							<option value="Comoren">Comoren</option>
							<option value="Congo">Congo</option>
							<option value="Congo, Democratische Republiek Congo">Congo, Democratische Republiek Congo</option>
							<option value="Cook Eilanden">Cook Eilanden</option>
							<option value="Costa Rica">Costa Rica</option>
							<option value="Ivoorkust">Ivoorkust</option>
							<option value="Kroatië">Kroatië</option>
							<option value="Cuba">Cuba</option>
							<option value="Curacao">Curacao</option>
							<option value="Cyprus">Cyprus</option>
							<option value="Tsjechië">Tsjechië</option>
							<option value="Denemarken">Denemarken</option>
							<option value="Djibouti">Djibouti</option>
							<option value="Dominica">Dominica</option>
							<option value="Dominicaanse Republiek">Dominicaanse Republiek</option>
							<option value="Ecuador">Ecuador</option>
							<option value="Egypte">Egypte</option>
							<option value="El Salvador">El Salvador</option>
							<option value="Equatoriaal-Guinea">Equatoriaal-Guinea</option>
							<option value="Eritrea">Eritrea</option>
							<option value="Estland">Estland</option>
							<option value="Ethiopië">Ethiopië</option>
							<option value="Falklandeilanden (Malvinas)">Falklandeilanden (Malvinas)</option>
							<option value="Faeröer">Faeröer</option>
							<option value="Fiji">Fiji</option>
							<option value="Finland">Finland</option>
							<option value="Frankrijk">Frankrijk</option>
							<option value="Frans Guyana">Frans Guyana</option>
							<option value="Frans-Polynesië">Frans-Polynesië</option>
							<option value="Franse zuidelijke gebieden">Franse zuidelijke gebieden</option>
							<option value="Gabon">Gabon</option>
							<option value="Gambia">Gambia</option>
							<option value="Georgië">Georgië</option>
							<option value="Duitsland">Duitsland</option>
							<option value="Ghana">Ghana</option>
							<option value="Gibraltar">Gibraltar</option>
							<option value="Griekenland">Griekenland</option>
							<option value="Groenland">Groenland</option>
							<option value="Grenada">Grenada</option>
							<option value="Guadeloupe">Guadeloupe</option>
							<option value="Guam">Guam</option>
							<option value="Guatemala">Guatemala</option>
							<option value="Guernsey">Guernsey</option>
							<option value="Guinea">Guinea</option>
							<option value="Guinee-Bissau">Guinee-Bissau</option>
							<option value="Guyana">Guyana</option>
							<option value="Haïti">Haïti</option>
							<option value="Heard Island en Mcdonald Islands">Heard Island en Mcdonald Islands</option>
							<option value="Heilige Stoel (Vaticaanstad)">Heilige Stoel (Vaticaanstad)</option>
							<option value="Honduras">Honduras</option>
							<option value="Hong Kong">Hong Kong</option>
							<option value="Hongarije">Hongarije</option>
							<option value="IJsland">IJsland</option>
							<option value="India">India</option>
							<option value="Indonesië">Indonesië</option>
							<option value="Iran, Islamitische Republiek">Iran, Islamitische Republiek</option>
							<option value="Irak">Irak</option>
							<option value="Ierland">Ierland</option>
							<option value="Isle of Man">Isle of Man</option>
							<option value="Israël">Israël</option>
							<option value="Italië">Italië</option>
							<option value="Jamaica">Jamaica</option>
							<option value="Japan">Japan</option>
							<option value="Jersey">Jersey</option>
							<option value="Jordanië">Jordanië</option>
							<option value="Kazachstan">Kazachstan</option>
							<option value="Kenia">Kenia</option>
							<option value="Kiribati">Kiribati</option>
							<option value="Korea, Democratische Volksrepubliek">Korea, Democratische Volksrepubliek</option>
							<option value="Korea, republiek van">Korea, republiek van</option>
							<option value="Kosovo">Kosovo</option>
							<option value="Koeweit">Koeweit</option>
							<option value="Kirgizië">Kirgizië</option>
							<option value="Lao Democratische Volksrepubliek">Lao Democratische Volksrepubliek</option>
							<option value="Letland">Letland</option>
							<option value="Libanon">Libanon</option>
							<option value="Lesotho">Lesotho</option>
							<option value="Liberia">Liberia</option>
							<option value="Libië">Libië</option>
							<option value="Liechtenstein">Liechtenstein</option>
							<option value="Litouwen">Litouwen</option>
							<option value="Luxemburg">Luxemburg</option>
							<option value="Macao">Macao</option>
							<option value="Macedonië, de voormalige Joegoslavische Republiek">Macedonië, de voormalige Joegoslavische Republiek</option>
							<option value="Madagascar">Madagascar</option>
							<option value="Malawi">Malawi</option>
							<option value="Maleisië">Maleisië</option>
							<option value="Maldiven">Maldiven</option>
							<option value="Mali">Mali</option>
							<option value="Malta">Malta</option>
							<option value="Marshall eilanden">Marshall eilanden</option>
							<option value="Martinique">Martinique</option>
							<option value="Mauritanië">Mauritanië</option>
							<option value="Mauritius">Mauritius</option>
							<option value="Mayotte">Mayotte</option>
							<option value="Mexico">Mexico</option>
							<option value="Micronesië, Federale Staten van">Micronesië, Federale Staten van</option>
							<option value="Moldavië, Republiek">Moldavië, Republiek</option>
							<option value="Monaco">Monaco</option>
							<option value="Mongolië">Mongolië</option>
							<option value="Montenegro">Montenegro</option>
							<option value="Montserrat">Montserrat</option>
							<option value="Marokko">Marokko</option>
							<option value="Mozambique">Mozambique</option>
							<option value="Myanmar">Myanmar</option>
							<option value="Namibië">Namibië</option>
							<option value="Nauru">Nauru</option>
							<option value="Nepal">Nepal</option>
							<option value="Nederland">Nederland</option>
							<option value="Nederlandse Antillen">Nederlandse Antillen</option>
							<option value="Nieuw-Caledonië">Nieuw-Caledonië</option>
							<option value="Nieuw-Zeeland">Nieuw-Zeeland</option>
							<option value="Nicaragua">Nicaragua</option>
							<option value="Niger">Niger</option>
							<option value="Nigeria">Nigeria</option>
							<option value="Niue">Niue</option>
							<option value="Norfolkeiland">Norfolkeiland</option>
							<option value="noordelijke Mariana eilanden">noordelijke Mariana eilanden</option>
							<option value="Noorwegen">Noorwegen</option>
							<option value="Oman">Oman</option>
							<option value="Pakistan">Pakistan</option>
							<option value="Palau">Palau</option>
							<option value="Palestijns gebied, bezet">Palestijns gebied, bezet</option>
							<option value="Panama">Panama</option>
							<option value="Papoea-Nieuw-Guinea">Papoea-Nieuw-Guinea</option>
							<option value="Paraguay">Paraguay</option>
							<option value="Peru">Peru</option>
							<option value="Filippijnen">Filippijnen</option>
							<option value="Pitcairn">Pitcairn</option>
							<option value="Polen">Polen</option>
							<option value="Portugal">Portugal</option>
							<option value="Puerto Rico">Puerto Rico</option>
							<option value="Qatar">Qatar</option>
							<option value="Bijeenkomst">Bijeenkomst</option>
							<option value="Roemenië">Roemenië</option>
							<option value="Russische Federatie">Russische Federatie</option>
							<option value="Rwanda">Rwanda</option>
							<option value="Sint-Bartholomeus">Sint-Bartholomeus</option>
							<option value="Sint-Helena">Sint-Helena</option>
							<option value="Saint Kitts en Nevis">Saint Kitts en Nevis</option>
							<option value="Saint Lucia">Saint Lucia</option>
							<option value="Sint-Maarten">Sint-Maarten</option>
							<option value="Saint Pierre en Miquelon">Saint Pierre en Miquelon</option>
							<option value="Saint Vincent en de Grenadines">Saint Vincent en de Grenadines</option>
							<option value="Samoa">Samoa</option>
							<option value="San Marino">San Marino</option>
							<option value="Sao Tomé en Principe">Sao Tomé en Principe</option>
							<option value="Saoedi-Arabië">Saoedi-Arabië</option>
							<option value="Senegal">Senegal</option>
							<option value="Servië">Servië</option>
							<option value="Servië en Montenegro">Servië en Montenegro</option>
							<option value="Seychellen">Seychellen</option>
							<option value="Sierra Leone">Sierra Leone</option>
							<option value="Singapore">Singapore</option>
							<option value="Sint Maarten">Sint Maarten</option>
							<option value="Slowakije">Slowakije</option>
							<option value="Slovenië">Slovenië</option>
							<option value="Solomon eilanden">Solomon eilanden</option>
							<option value="Somalië">Somalië</option>
							<option value="Zuid-Afrika">Zuid-Afrika</option>
							<option value="Zuid-Georgië en de Zuidelijke Sandwicheilanden">Zuid-Georgië en de Zuidelijke Sandwicheilanden</option>
							<option value="Zuid Soedan">Zuid Soedan</option>
							<option value="Spanje">Spanje</option>
							<option value="Sri Lanka">Sri Lanka</option>
							<option value="Soedan">Soedan</option>
							<option value="Suriname">Suriname</option>
							<option value="Svalbard en Jan Mayen">Svalbard en Jan Mayen</option>
							<option value="Swaziland">Swaziland</option>
							<option value="Zweden">Zweden</option>
							<option value="Zwitserland">Zwitserland</option>
							<option value="Syrische Arabische Republiek">Syrische Arabische Republiek</option>
							<option value="Taiwan, provincie China">Taiwan, provincie China</option>
							<option value="Tadzjikistan">Tadzjikistan</option>
							<option value="Tanzania, Verenigde Republiek">Tanzania, Verenigde Republiek</option>
							<option value="Thailand">Thailand</option>
							<option value="Oost-Timor">Oost-Timor</option>
							<option value="Gaan">Gaan</option>
							<option value="Tokelau">Tokelau</option>
							<option value="Tonga">Tonga</option>
							<option value="Trinidad en Tobago">Trinidad en Tobago</option>
							<option value="Tunesië">Tunesië</option>
							<option value="kalkoen">kalkoen</option>
							<option value="Turkmenistan">Turkmenistan</option>
							<option value="Turks- en Caicoseilanden">Turks- en Caicoseilanden</option>
							<option value="Tuvalu">Tuvalu</option>
							<option value="Oeganda">Oeganda</option>
							<option value="Oekraïne">Oekraïne</option>
							<option value="Verenigde Arabische Emiraten">Verenigde Arabische Emiraten</option>
							<option value="Verenigd Koningkrijk">Verenigd Koningkrijk</option>
							<option value="Verenigde Staten">Verenigde Staten</option>
							<option value="Kleine afgelegen eilanden van de Verenigde Staten">Kleine afgelegen eilanden van de Verenigde Staten</option>
							<option value="Uruguay">Uruguay</option>
							<option value="Oezbekistan">Oezbekistan</option>
							<option value="Vanuatu">Vanuatu</option>
							<option value="Venezuela">Venezuela</option>
							<option value="Vietnam">Vietnam</option>
							<option value="Britse Maagdeneilanden">Britse Maagdeneilanden</option>
							<option value="Maagdeneilanden, U.s.">Maagdeneilanden, U.s.</option>
							<option value="Wallis en Futuna">Wallis en Futuna</option>
							<option value="Westelijke Sahara">Westelijke Sahara</option>
							<option value="Jemen">Jemen</option>
							<option value="Zambia">Zambia</option>
							<option value="Zimbabwe">Zimbabwe</option>
						</select>
					</div>               
                    <div class="field">
                        <label for="homeLanguage">{{screens.homeLanguage}}</label>
                        <input v-model="testData.homeLanguage" type="text" name="homeLanguage" :placeholder="screens.homeLanguagePlaceholder" autocomplete="off">
                    </div>
                    <div class="field">
                        <label for="ethnicity">{{screens.ethnicity}}</label>
                        <select v-model="testData.ethnicity" v-bind:class="{ placeholder: !testData.ethnicity }" name="ethnicity" :placeholder="screens.ethnicityPlaceholder">
                            <option value="" disabled hidden>{{screens.ethnicityPlaceholder}}</option>
                            <option value="No answer">{{screens.ethnicityNoAnswer}}</option>
                            <option value="African/Black">{{screens.ethnicityBlack}}</option>
                            <option value="Arab">{{screens.ethnicityArab}}</option>
                            <option value="Asian">{{screens.ethnicityAsian}}</option>
                            <option value="Caucasian/White">{{screens.ethnicityWhite}}</option>
                            <option value="Indian">{{screens.ethnicityIndian}}</option>
                            <option value="Latin">{{screens.ethnicityLatin}}</option>
                            <option value="Mediterranian">{{screens.ethnicityMediterranian}}</option>
                            <option value="Multi-Racial">{{screens.ethnicityMultiRacial}}</option>
                            <option value="Other">{{screens.ethnicityOther}}</option>
                        </select>
                    </div>
                </div>
                <div class="prev" v-on:click="previous()"></div>
                <div class="next" v-on:click="next()"></div>
            </div>
        </div>

        <!-- Information -->
        <div class="container" v-if="showInformation">
            <div class="bubble left nextArrow textPage">
                {{screens.clickArrowContent}}
            </div>
            <div class="page">
                <h2>{{information.header}}</h2>
                <p v-if="information.paragraph1" v-html="information.paragraph1"></p>
                <p v-if="information.paragraph2" v-html="information.paragraph2"></p>
                <p v-if="information.paragraph3" v-html="information.paragraph3"></p>
                <div class="prev" v-if="showPrevious" v-on:click="previous()"></div>
                <div class="next" v-on:click="next()"></div>
            </div>
        </div>

        <!-- Item overview -->

        <!-- Training -->
        <lazy-LPCATDemo v-if="showTraining" :phase="phase" :translations="demo" @training-ended="trainingEnded"/>

        <!-- Test -->
        <lazy-LPCATTest v-if="showTest"
            :phase="phase"
            :test-completed="testCompleted"
            :test-data="testData" />

        <!-- Consent -->
        <div v-if="showConsent" class="container">
            <div class="page consent">

                <h2>{{screens.consent.header}}</h2>
                <p v-if="screens.consent.paragraph1" v-html="screens.consent.paragraph1"></p>
                <p v-if="screens.consent.paragraph2" v-html="screens.consent.paragraph2"></p>
                <p v-if="screens.consent.paragraph3" v-html="screens.consent.paragraph3"></p>

                <div class="buttons">
                    <div class="block">
                        <div class="thumbs-down selected" v-on:click="disagree()"></div>
                        <div class="text" v-on:click="disagree()"><h5>{{screens.disagree}}</h5></div>
                    </div>
                    <div class="block">
                        <div class="thumbs-up selected" v-on:click="agree()"></div>
                        <div class="text" v-on:click="agree()"><h5>{{screens.agree}}</h5></div>
                    </div>
                </div>

                <div class="prev" v-on:click="previous()"></div>
            </div>
        </div>


    </div>
</template>

<script>

import moment from 'moment/src/moment'
import getUserLocale from 'get-user-locale'
import firebase from "../../firebase";
import Loader from "../loader/Loader.vue";

const firestore = firebase.firestore();

export default {
    name: 'LPCAT',
	props: {
		isAdmin: Boolean,
	},    
    mounted () {
        this.$cookie.set('uuid', '823f5d82-3dc2-4360-a9a2-1cb794f2d52b');
        this.lpcatApisWarmedUp = false;
        this.loadedTranslations = false;
            
        firestore
            .collection("translations")
            .onSnapshot((querySnapshot) => {
                const newTranslations = [];
                this.publishedLanguages = [];
                this.unpublishedLanguages = [];
                querySnapshot.forEach((doc) => {
                    const language = doc.data();
                    const languageKey = doc.id;
                    newTranslations[languageKey] = language;

                    if (language.published) {
                        this.publishedLanguages.push(languageKey);
                    } else {
                        this.unpublishedLanguages.push(languageKey);
                    }
                });
                this.translations = newTranslations;
                this.loadedTranslations = true;
                this.setLanguage(this.getLanguage());
                
                if (this.isFirstLoad) {
                    this.isFirstLoad = false;
                    this.render();
                    this.$nextTick(() => {
                        this.$refs.video.playbackRate = 0.6
                    })
                }
            });

        const loadItem = firebase.functions().httpsCallable('item');
        loadItem({phase: 1, answers: []}).then(() => {
            this.lpcatApisWarmedUp = true;
        });
    },
    methods: {
        gotoPortal () {
            this.$emit('goto-portal')
        },
        reset () {
            const language = this.getLanguage();

            this.testData = {
                gender: '',
                ethnicity: '',
                country: '',
                educationLevel: '',
                answers: [],
                consent: false,
                language
            }
        },
        setLanguage (language) {
            this.language = this.translations.hasOwnProperty(language) ? language : 'EN';
            this.demo = JSON.parse(this.translations[language].demo);
            this.screens = JSON.parse(this.translations[language].screens);
            this.$cookie.set('language', language);
            this.testData.language = language;
            this.render();
        },
        getLanguage() {
            const language = this.$cookie.get('language')
            if (language) return language;

            const browserLanguage = getUserLocale().split('_-')[0].toUpperCase();
            if (this.translations.hasOwnProperty(browserLanguage)) return browserLanguage;

            return 'EN';
        },
        isLanguage (language) {
            return this.language === language;
        },
        agree () {
            this.testData.consent = true
            this.next()
        },
        disagree () {
            this.testData.consent = false
            this.next()
        },
        next () {
            this.pageIndex++

            // Disable examples
            // if (this.pageIndex === 6) {
            // 	this.pageIndex = 8
            // }
            // if (this.pageIndex === 12) {
            // 	this.pageIndex = 14
            // }

            this.render()
        },
        previous () {
            this.pageIndex--
            this.render()
        },
        trainingEnded (languageScore) {
            this.languageScore = languageScore
            this.next()
        },
        testCompleted (testData) {
            this.testData = testData
            this.next()
        },
        isValidDemographics () {
            this.showGenderError = !this.testData.gender
            this.showFirstNameError = !this.testData.firstname
            this.showLastNameError = !this.testData.lastname
            this.showAgeError = !this.testData.age
            this.showEducationError = !this.testData.educationLevel

            return !this.showGenderError &&
                !this.showFirstNameError &&
                !this.showLastNameError &&
                !this.showAgeError &&
                !this.showEducationError
        },
        render () {
            this.showStart = false
            this.showDemographics = false
            this.showOptionals = false
            this.showInformation = false
            this.showTest = false
            this.showTraining = false
            this.showTrainingMarie = false
            this.showNameError = false
            this.showSurnameError = false
            this.showIDError = false
            this.showConsent = false
            switch (this.pageIndex) {
                case 0:
                    this.phase = 1
                    this.showStart = true
                    // this.showTrainingMarie = true
                    this.reset()
                    break
                case 1:
                    this.information = this.screens.introduction
                    this.showStart = false
                    this.showInformation = true
                    break
                case 2:
                    this.showInformation = false
                    this.showDemographics = true
                    break
                case 3:
                    if (!this.isValidDemographics()) {
                        this.previous();
                        break;
                    }
                    if (this.testData.country === '') {
                        this.testData.country = this.language === 'NL' ? 'Nederland' : ''
                    }
                    this.showDemographics = false
                    this.showOptionals = true
                    break
                case 4:
                    this.showOptionals = false
                    this.showConsent = true
                    break
                case 5:
                    this.information = this.screens.examples1Instructions
                    this.showConsent = false
                    this.showInformation = true
                    break
                case 6:
                    this.phase = 1
                    this.trainingTimer1 = moment().unix()
                    this.showInformation = false
                    this.showPrevious = true
                    this.showTraining = true
                    break
                case 7:
                    this.trainingTimer1 = moment().unix() - this.trainingTimer1
                    this.information = this.screens.pretestInstructions
                    this.showTraining = false
                    this.showPrevious = false
                    this.showInformation = true
                    break
                case 8:
                    this.questionsTimer1 = moment().unix()
                    this.testData.answers = []
                    this.phase = 1
                    this.showInformation = false
                    this.showPrevious = true
                    this.showTest = true
                    break
                case 9:
                    this.questionsTimer1 = moment().unix() - this.questionsTimer1
                    this.information = this.screens.pretestDone
                    this.showTest = false
                    this.showPrevious = false
                    this.showInformation = true
                    break
                case 10:
                    this.trainingTimer2 = moment().unix()
                    this.phase = 2
                    this.showInformation = false
                    this.showPrevious = true
                    this.showTraining = true
                    break
                case 11:
                    this.trainingTimer2 = moment().unix() - this.trainingTimer2
                    this.information = this.screens.postTestInstructions
                    this.showTraining = false
                    this.showPrevious = false
                    this.showInformation = true
                    break
                case 12:
                    this.questionsTimer2 = moment().unix()
                    this.phase = 2
                    this.showInformation = false
                    this.showPrevious = true
                    this.showTest = true
                    break
                case 13:
                    this.questionsTimer2 = moment().unix() - this.questionsTimer2
                    this.information = this.screens.finished
                    this.showTest = false
                    this.showPrevious = false
                    this.showInformation = true
                    this.testData.created = moment().utc().valueOf()
                    this.testData.questionsTimer1 = this.questionsTimer1
                    this.testData.questionsTimer2 = this.questionsTimer2
                    this.testData.trainingTimer1 = this.trainingTimer1
                    this.testData.trainingTimer2 = this.trainingTimer2
                    this.testData.languageScore = this.languageScore
                    this.$emit('save-lpcat-result', this.testData)
                    this.reset()
                    break
                case 14:
                    this.showInformation = false
                    this.showPrevious = true
                    this.showStart = true
                    this.pageIndex = 0
            }
        }
    },
    components: {
        // LPCATOverview,
        Loader,
        lazyLPCATTest: () => import('./LPCATTest'),
        lazyLPCATDemo: () => import('./LPCATDemo')
    },
    data () {
        return {
            loadedTranslations: false,
            lpcatApisWarmedUp: false,

            // Translations
            isFirstLoad: true,
            language: false,
            demo: false,
            screens: false,

            pageIndex: 0,
            languageScore: 0,
            phase: 1,
            testData: {
                gender: '',
                ethnicity: '',
                country: '',
                educationLevel: '',
                answers: [],
                consent: false,
                language: 'EN'
            },
            trainingTimer1: 0,
            trainingTimer2: 0,
            questionsTimer1: 0,
            questionsTimer2: 0,
            showStart: false,
            showInformation: false,
            showTest: false,
            showTraining: false,
            showTrainingMarie: false,
            showDemographics: false,
            showOptionals: false,
            showConsent: false,
            showPrevious: true,
            showGenderError: false,
            showEducationError: false,
            showFirstNameError: false,
            showLastNameError: false,
            showAgeError: false,
            information: false,

            publishedLanguages: [],
            unpublishedLanguages: []
        }
    }
}
</script>

<style scoped>
    .language {
        position: fixed !important;
        width: 120px;
        color: white;
        font-size: 14px;
        font-weight: bold;
        z-index: 99999;
        top: 20px;
        right: 20px;
        text-shadow: none;
    }
    
    .language span {
        display: inline-block;
        color: yellow;
        font-size: 12px;
        font-weight:bold;
        margin-top: 8px;
        margin-bottom: 8px;
        margin-left: 8px;
        margin-bottom: 8px;
        text-align: center;
        width: 104px;
        padding: 12px 0px;
        border-radius: 6px;
        background-color: rgba(0, 0, 0, 0.2);
    }

    .language div {
        float: left;
        margin-left: 8px;
        margin-bottom: 8px;
        text-align: center;
        width: 48px;
        padding: 12px;
        border-radius: 6px;
        background-color: rgba(0, 0, 0, 0.2);
    }

    .language div:hover, .language div.selected {
        color: yellow;
        background-color: rgba(0, 0, 0, 0.4);
    }

    .lpcat {
        height: 100%;
        background: #303030;
        background: url(../../assets/img/background.jpg) center repeat scroll;
        color: #c3c3c3;
        font: 16px/24px "Helvetica Neue", Arial, Helvetica, Geneva, sans-serif;
        text-shadow: 0 1px 2px rgba(0, 0, 0, 0.8);
        font-weight: 400;
    }
    h1 {
        color: #fff;
        font-size: 60px;
        line-height: 72px;
        font-weight: 300;
        margin-bottom: 36px;
    }
    h2 {
        color: #61ae24;
        font-size: 36px;
        line-height: 36px;
        font-weight: 300;
        margin-bottom: 18px;
    }
    h3 {
        color: #61ae24;
        font-size: 24px;
        line-height: 32px;
        font-weight: bold;
        margin-bottom: 16px;
    }
    h4 {
        color: #61ae24;
        font-size: 20px;
        line-height: 24px;
        margin-bottom: 6px;
    }
    h5 {
        color: #c3c3c3;
        font-size: 18px;
        line-height: 24px;
        margin-bottom: 6px;
    }
    h6 {
        color: #c3c3c3;
        font-size: 14px;
        line-height: 18px;
        margin-bottom: 6px;
    }
    p {
        margin-bottom: 12px;
    }
    a {
        color: #61ae24;
        text-decoration: none;
        -webkit-transition: color 0.15s ease-in-out;
        -moz-transition: color 0.15s ease-in-out;
        transition: color 0.15s ease-in-out;
    }
    a:hover {
        color: #6dc129;
    }
    .color {
        color: #61ae24;
    }
    .blue {
        color: #00a1cb;
    }
    .green {
        color: #61ae24;
    }
    .orange {
        color: #f18d05;
    }
    .red {
        color: #e54028;
    }
    .hide {
        display: none;
    }
    button {
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        font: 16px/24px "Helvetica Neue", Arial, Helvetica, Geneva, sans-serif;
        color: #fff;
        font-size: 18px;
        text-shadow: 0 1px 2px #222;
        background-color: #616161;
        margin: 0;
        padding: 0 10px;
        width: 193px;
        height: 36px;
        border: 1px solid rgba(0,0,0, 0.3);
        -webkit-box-shadow: inset 0 1px 0 rgba(255,255,255,0.3), 0 2px 2px rgba(0,0,0,0.4), 0 0 4px 1px rgba(0,0,0,0.2);
        -moz-box-shadow: inset 0 1px 0 rgba(255,255,255,0.3), 0 2px 2px rgba(0,0,0,0.4), 0 0 4px 1px rgba(0,0,0,0.2);
        box-shadow: inset 0 1px 0 rgba(255,255,255,0.3), 0 2px 2px rgba(0,0,0,0.4), 0 0 4px 1px rgba(0,0,0,0.2);
        -webkit-border-radius: 8px;
        -moz-border-radius: 8px;
        border-radius: 8px;
        -webkit-transition: background-color 0.15s ease-in-out;
        -moz-transition: background-color 0.15s ease-in-out;
        transition: background-color 0.15s ease-in-out;
        cursor: pointer;
    }
    button.active,
    button:hover {
        background-color: #61ae24;
    }
    label {
        color: #c3c3c3;
        font-size: 18px;
        line-height: 24px;
        margin-bottom: 12px;
    }
    input,
    select,
    textarea {
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        font: 18px/24px "Helvetica Neue", Arial, Helvetica, Geneva, sans-serif;
        font-weight: 300;
        color: #61ae24;
        margin: 0;
        padding: 3px;
        background-color: #424242;
        border: 1px solid #424242;
        border-top-color: #353535;
        border-right-color: #404040;
        border-bottom-color: #5c5c5c;
        border-left-color: #404040;
        -webkit-border-radius: 0;
        -moz-border-radius: 0;
        border-radius: 0;
    }

    ::placeholder, .placeholder {
        color: #888 !important;
    }

    select {
        -moz-appearance: none;
        text-indent: 0.01px;
        text-overflow: '';
    }
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
    .line {
        background: url(../../assets/img/line.png) center center no-repeat;
        height: 4px;
        margin-top: 48px;
        margin-bottom: 48px;
    }

    /* Layout */

    .container {
        width: 956px;
        position: absolute;
        top: 50%;
        left: 50%;
        margin-left: -478px;
        margin-top: -301px;
        /*display: none;*/
    }
    .container.show {
        display: block;
    }
    .page {
        background-color: #3e3e3e;
        height: 600px;
        /* min-height: 436px; */
        padding: 40px 34px 114px;
        -webkit-box-shadow: inset 0 1px 2px rgba(0,0,0,0.95), inset 0 -1px 2px rgba(255,255,255,0.15);
        -moz-box-shadow: inset 0 1px 2px rgba(0,0,0,0.95), inset 0 -1px 2px rgba(255,255,255,0.15);
        box-shadow: inset 0 1px 2px rgba(0,0,0,0.95), inset 0 -1px 2px rgba(255,255,255,0.15);
        -webkit-border-radius: 16px;
        -moz-border-radius: 16px;
        border-radius: 16px;
        position: relative;
    }
    .page.nobuttons {
        min-height: 494px;
        padding-bottom: 56px;
    }
    .quit,
    .prev,
    .next,
    .thumbs-up,
    .thumbs-down,
    .settings,
    .help {
        border: 1px solid rgba(0,0,0, 0.3);
        width: 60px;
        height: 60px;
        position: absolute;
        bottom: 31px;
        -webkit-box-shadow: inset 0 1px 0 rgba(255,255,255,0.3), 0 2px 2px rgba(0,0,0,0.4), 0 0 4px 1px rgba(0,0,0,0.2);
        -moz-box-shadow: inset 0 1px 0 rgba(255,255,255,0.3), 0 2px 2px rgba(0,0,0,0.4), 0 0 4px 1px rgba(0,0,0,0.2);
        box-shadow: inset 0 1px 0 rgba(255,255,255,0.3), 0 2px 2px rgba(0,0,0,0.4), 0 0 4px 1px rgba(0,0,0,0.2);
        -webkit-border-radius: 8px;
        -moz-border-radius: 8px;
        border-radius: 8px;
        -webkit-transition: background-color 0.15s ease-in-out;
        -moz-transition: background-color 0.15s ease-in-out;
        transition: background-color 0.15s ease-in-out;
        cursor: pointer;
    }
    .quit {
        background: url(../../assets/img/quit.png) center center no-repeat #616161;
        background-image: url(../../assets/img/quit.svg), none;
        left: 31px;
    }
    .quit:hover {
        background-color: #545454;
    }
    .prev {
        background: url(../../assets/img/prev.png) center center no-repeat #616161;
        background-image: url(../../assets/img/prev.svg), none;
        left: 31px;
    }
    .prev:hover {
        background-color: #545454;
    }
    .prev.orange {
        background: url(../../assets/img/prev.png) center center no-repeat #f18d05;
        background-image: url(../../assets/img/prev.svg), none;
        left: 31px;
    }
    .prev.orange:hover {
        background-color: #f7981b;
    }
    .next {
        background: url(../../assets/img/next.png) center center no-repeat #61ae24;
        background-image: url(../../assets/img/next.svg), none;
        right: 31px;
    }
    .next:hover {
        background-color: #6dc129;
    }
    .thumbs-up {
        position: relative;
        left: -30px;
        margin-left: 50%;
        top: 0;
        margin-bottom: 12px;
        background: url(../../assets/img/thumbs-up.png) center center no-repeat #616161;
        background-image: url(../../assets/img/thumbs-up.svg), none;
    }
    .thumbs-down {
        position: relative;
        left: -30px;
        margin-left: 50%;
        top: 0;
        margin-bottom: 12px;
        background: url(../../assets/img/thumbs-down.png) center center no-repeat #616161;
        background-image: url(../../assets/img/thumbs-down.svg), none;
    }
    .thumbs-up.selected {
        background-color: #61ae24;
    }
    .thumbs-down.selected {
        background-color: #e54028;
    }
    .thumbs-up:hover {
        background-color: #6dc129;
    }
    .thumbs-down:hover {
        background-color: #e64f3b;
    }
    .help {
        background: url(../../assets/img/help.png) center center no-repeat #333333;
        background-image: url(../../assets/img/help.svg), none;
        left: 31px;
    }
    .help:hover {
        background-color: #3e3e3e;
    }

    /* Settings */

    .settings {
        background: url(../../assets/img/settings-61.png) right 8px no-repeat #3e3e3e;
        background-image: url(../../assets/img/settings-61.svg), none;
        height: 68px;
        width: auto;
        min-width: 60px;
        position: fixed;
        top: -8px;
        right: 16px;
    }
    .settings:hover {
        background-color: #383838;
    }
    .settings .toggle {
        width: 60px;
        height: 61px;
        margin-top: 7px;
        right: 0;
        top: 0;
        cursor: pointer;
        float: right;
    }
    .settings select {
        background-color: #323232;
        margin: 21px 20px 0;
        float: left;
        display: none;
    }

    /* Inline icons */

    span.inline {
        width: 21px;
        height: 16px;
        margin: 0 2px;
        display: inline-block;
        -webkit-background-size: cover;
        -moz-background-size: cover;
        -o-background-size: cover;
        background-size: cover;
    }
    .spannext {
        background: url(../../assets/img/next.png);
        background-image: url(../../assets/img/next.svg), none;
    }
    .spanprev {
        background: url(../../assets/img/prev.png);
        background-image: url(../../assets/img/prev.svg), none;
    }

    /* Forms ( CPT style defaults ) */

    .form {
        padding-top: 26px;
    }
    .form .field {
        min-height: 52px;
        clear: both;
    }
    .form .field.textarea {
        min-height: 52px;
        clear: both;
    }
    .form  label {
        text-shadow: 0 1px 2px #222;
        width: 213px;
        display: block;
        float: left;
        margin-top: 4px;
    }
    .form input {
        width: 406px;
        height: 32px;
    }
    .form select {
        width: 406px;
        height: 32px;
        margin: 0;
        background: url(../../assets/img/select.png) no-repeat 386px center #424242;
    }
    .form textarea {
        width: 398px;
        height: 78px;
    }
    .form .error {
        font-size: 14px;
        line-height: 18px;
        background: #61ae24;
        width: 181px;
        margin-top: -31px;
        padding: 6px 12px;
        position: absolute;
        right: 62px;
        -webkit-border-radius: 4px;
        -moz-border-radius: 4px;
        border-radius: 4px;
        margin-right: 0;
        color: #fff;
    }
    .form .error:after {
        content: "";
        position: absolute;
        top: 11px;
        left: -3px;
        -webkit-transform: rotate(45deg);
        -moz-transform: rotate(45deg);
        transform: rotate(45deg);
        border-width: 4px;
        border-style: solid;
        border-color: transparent transparent #61ae24 #61ae24;
    }

    /* Form Slide ( LPCAT style )*/

    .formslide h2 {
        padding-bottom: 19px;
        float: left;
    }
    .formslide h6 {
        float: right;
        margin-top: 17px;
    }
    .formslide .col1 {
        float: left;
        width: 390px;
    }
    .formslide .col2 {
        float: left;
        width: 390px;
    }
    .formslide .col3 {
        float: right;
    }
    .formslide .form {
        clear: both;
    }
    .formslide label {
        width: 110px;
        display: block;
        float: left;
    }
    .formslide input, .formslide select, .formslide textarea {
        display: block;
        float: left;
    }
    .formslide input {
        width: 244px;
    }
    .formslide select {
        width: 252px;
        background: url(../../assets/img/select.png) no-repeat 232px center #424242;
    }
    .formslide textarea {
        width: 244px;
        height: 78px;
    }

    .formslide .error {
        position: absolute;
        background-color: #61ae24;
        left: 172px;
        height: 20px;
        margin-top: -40px;
        width: 228px;
        text-align: center;
        -moz-box-shadow: 0 2px 4px #272727;
        -webkit-box-shadow: 0 2px 4px #272727;
        box-shadow: 0 2px 4px #272727;
    }
    .formslide .error:after {
        top: inherit;
        left: 50%;
        bottom: -4px;
        margin-left: -4px;
        -webkit-transform: rotate(-45deg);
        -moz-transform: rotate(-45deg);
        transform: rotate(-45deg);
    }

    /* Intro */

    .intro {
        background-repeat: no-repeat;
    }
    .page.video {
        background: none;
    }
    .intro video {
        position: fixed;
    right: 0;
    bottom: 0;
    min-width: 100%;
    min-height: 100%;
        /* width: 956px;
        height: 602px;
        position: absolute;
        top: 0;
        left: 0;
        -webkit-border-radius: 16px;
        -moz-border-radius: 16px;
        border-radius: 16px; */
    }
    .intro .video-corner1 {
        display: none;
        background: url(../../assets/img/video-corner1.png) 0 0 no-repeat;
        width: 20px;
        height: 602px;
        position: absolute;
        top: 0;
        left: 0;
    }
    .intro .video-corner2 {
        display: none;
        background: url(../../assets/img/video-corner2.png) 0 0 no-repeat;
        width: 20px;
        height: 602px;
        position: absolute;
        top: 0;
        right: 0;
    }
    .intro .lpcatlogo {
        position: fixed !important;
        background: url(../../assets/img/lpcatlogo.png) 0 0 no-repeat;
        background-image: url(../../assets/img/lpcatlogo.svg), none;
        width: 182px;
        height: 72px;
        position: absolute;
        top: 52px;
        left: 62px;
    }
    .intro .start {
        position: relative;
        text-align: center;
        background: url(../../assets/img/icons.png) center 20px no-repeat;
        background-image: url(../../assets/img/icons.svg), none;
        width: 140px;
        margin: 162px auto 0;
    }
    .intro a {
        color: white;
        font-size: 20px;
        line-height: 24px;
        text-transform: lowercase;
        text-shadow: none;
        display: block;
        padding-top: 120px;
    }
    .intro a:hover {
        text-shadow: 0 0 6px rgb(255, 255, 255);
    }
    .intro .version {
        position: fixed !important;
        color: white;
        font-size: 13px;
        position: absolute;
        bottom: 20px;
        left: 31px;
        text-shadow: none;
    }
    .mmlogo {
        position: fixed !important;
        background: url(../../assets/img/mmlogo.png) 0 0 no-repeat;
        background-image: url(../../assets/img/mmlogo.svg), none;
        width: 176px;
        height: 22px;
        position: absolute;
        right: 33px;
        bottom: 27px;
    }

    /* Consent */

    .consent .buttons {
        width: 548px;
        margin: 77px auto 0;
    }

    .consent .buttons .block {
        width: 50%;
        float: left;
        text-align: center;
    }

    /* Bubbles */

    .bubble {
        position: absolute;
        background-color: rgba(97,174,36,.1);
        -moz-border-radius: 8px;
        -webkit-border-radius: 8px;
        border-radius: 8px;
        padding: 8px 12px;
        margin-top: -9px;
        margin-left: -8px;
        font-size: 15px;
        line-height: 20px;
        color: #fff;
    }
    .bubble:after {
        content:"";
        position: absolute;
        width: 0;
        height: 0;
    }
    .bubble.above {
        height: 100px;
        top: 7px;
    }
    .bubble.above:after {
        top: 100%;
        border-left: 12px solid transparent;
        border-top: 12px solid rgba(97,174,36,.1);
        border-right: 12px solid transparent;
    }
    .bubble.below {
        height: 100px;
        bottom: 7px;
    }
    .bubble.below:after {
        bottom: 100%;
        border-left: 12px solid transparent;
        border-bottom: 12px solid rgba(97,174,36,.1);
        border-right: 12px solid transparent;
    }
    .bubble.left {
        width: 86px;
        left: 7px;
    }
    .bubble.left:after {
        left: 100%;
        border-bottom: 18px solid transparent;
        border-left: 14px solid rgba(97,174,36,.1);
        border-top: 18px solid transparent;
        margin-left: 0px;
    }
    .bubble.right {
        width: 86px;
        right: 7px;
    }
    .bubble.right:after {
        right: 100%;
        border-top: 12px solid transparent;
        border-right: 12px solid rgba(97,174,36,.1);
        border-bottom: 12px solid transparent;
    }
    .bubble.full {
        width: 702px;
        left: 132px;
        height: 120px;
        min-height: 110px !important;
        z-index: 11;
    }
    .bubble.full:after {
        left: 630px;
    }
    .bubble.full.showfa {
        /* width: 686px; */
        /* left: 136px; */
    }
    .bubble.full.showfa:after {
        /* left: 610px; */
    }
    .bubble.full.showpc {
        width: 250px;
        height: 416px !important;
        top: 10px;
        left: auto;
        right: 0px;
        z-index: 11;
    }
    .bubble.full.showpc:after {
        left: -24px !important;
        top: 50px !important;
    }
    .bubble.full.showdemo:after {
        display: none;
    }
    .bubble.nextArrow.textPage {
        left: auto;
        right: 120px;
        top: 532px;
    }
    .bubble.nextArrow {
        width: auto;
        left: auto;
        right: 90px;
        top: 564px;
        z-index: 1;
        border-top-right-radius: 0px;
        border-bottom-right-radius: 0px;
    }
    .bubble.nextArrow:after {
        top: 0px;
    }

    /* Bubble Widths */


    .w200 { width: 200px; } .w200:after { left: 100px; }
    .w300 { width: 300px; } .w300:after { left: 150px; }
    .w400 { width: 400px; } .w400:after { left: 200px; }

    /* Bubble heights */

    .h40 { height: 40px; } .h40:after { top: 20px; }
    .h60 { height: 60px; } .h60:after { top: 30px; }
    .h80 { height: 80px; } .h80:after { top: 40px; }
    .h100 { height: 100px; } .h100:after { top: 50px; }
    .h120 { height: 120px; } .h120:after { top: 60px; }
    .h140 { height: 140px; } .h140:after { top: 70px; }
    .h160 { height: 160px; } .h160:after { top: 80px; }
    .h180 { height: 180px; } .h180:after { top: 90px; }
    .h200 { height: 200px; } .h200:after { top: 100px; }

    /* End */

</style>
