<template>
  <div id="app">
    <Loader :isLoading="isLoaderVisible()" />
    <Pin
      v-if="isPINVisibile()"
      :isPINVisibile="isPINVisibile()"
      @pin-unlock="pinUnlock"
      @pin-cancel="pinCancel"
      @log-user-out="logout"
    />
    <Login v-if="isLoginVisible()" @log-in-user="login" />
    <Portal
      v-if="isPortalVisible()"
      :user="selectedUser"
      :folders="folders"
      :results="results"
      :runs="runs"
      :selectedFolderId="selectedFolderId"
      :selectedResultId="selectedResultId"
      :selectedFolderName="selectedFolderName"
      :sort="sort"
      :asc="asc"
      :searchInput="searchText"
      :isTrash="isTrash"
      :isShared="isShared"
      :isLinked="isLinked"
      :isVoucher="isVoucher"
      :importLegacyResults="importLegacyResults"
      @log-user-out="logout"
      @add-folder="addFolder"
      @delete-folder="deleteFolder"
      @trash-folder="trashFolder"
      @empty-trash="emptyTrash"
      @select-folder="selectFolder"
      @delete-result="deleteResult"
      @move-result-to-trash="moveResultToTrash"
      @select-result="selectResult"
      @view-result="viewResult"
      @start-lpcat="startLPCAT"
      @sort="sortColumns"
      @search="searchResults"
    />
    <LPCATPage
      v-if="isLPCATVisible()"
      :isAdmin="user.admin"
      @save-lpcat-result="saveLPCATResult"
      @goto-portal="showPortal()"
    />
    <LPCATResult
      v-if="isLPCATResultVisible()"
      :result="result"
      :showXL="showXL"
      @close-result="closeResult"
    />
  </div>
</template>

<script>
import firebase from "./firebase";
import Loader from "./components/loader/Loader.vue";
import Pin from "./components/pin/Pin.vue";
import Portal from "./components/portal/Portal.vue";
import Login from "./components/login/Login.vue";
import LPCATPage from "./components/lpcat/LPCATPage.vue";
import LPCATResult from "./components/lpcat-result/LPCATResult.vue";

const firestore = firebase.firestore();
const functions = firebase.functions();

if (location.hostname === "localhost") {
  firestore.useEmulator("localhost", 8080);
  functions.useEmulator("localhost", 5001);
}

export default {
  name: "app",
  components: {
    Loader,
    Pin,
    Portal,
    Login,
    LPCATPage,
    LPCATResult,
  },
  data() {
    return {
      user: false,
      selectedUser: null,             
      sort: "created",
      asc: false,
      searchText: "",
      selectedFolderId: "",
      selectedResultId: null,
      selectedFolderName: "",
      folders: null,
      results: null,
      result: null,
      properties: null,
      runs: 0,
      isLocked: false,
      isLPCATRunning: false,
      isLoading: true,
      isStartup: true,
      isFolderActionBusy: false,
      isResultActionBusy: false,
      isReportActionBusy: false,
      isEmailActionBusy: false,
      isTrash: false,
      isLinked: false,
      isVoucher: false,
      isShared: false,
      errorState: false,
      saveCall: functions.httpsCallable("save"),
      processCall: functions.httpsCallable("process"),
      importLegacyCall: functions.httpsCallable("importLegacy"),
    };
  },
  beforeMount() {
    this.checkLogin();
  },
  computed: {
    showXL: function() {
      return this.user.email === "marie@mminitiatives.com";
    },
  },
  mounted() {
    let uuid = this.$cookie.get("uuid");
    let locked = !!uuid && uuid !== "";
    this.isLocked = locked;
    this.isLPCATRunning = locked;
    this.sort =
      this.$cookie.get("sort") === null ? "created" : this.$cookie.get("sort");
    this.asc = this.$cookie.get("asc") === "true" ? true : false;
    window.addEventListener("keyup", this.keyUpHandler);
    document.title = "M&M Initiatives";

    this.isStartup = false;
  },
  beforeDestroy() {
    window.removeEventListener("keyup", this.keyUpHandler);
  },
  methods: {
    // Authentication
    unlock() {
      this.isLocked = false;
    },
    checkLogin() {
      this.isLoading = true;
      firebase.auth().onAuthStateChanged((authorizedUser) => {
        if (authorizedUser) {
          firestore
            .collection("users")
            .doc(authorizedUser.email)
            .get()
            .then((doc) => {
              if (doc.exists) {
                this.user = doc.data();
                this.selectedUser = {
                  email: this.user.email,
                  company: this.user.company,
                  admin: this.user.admin                  
                };
                this.loadProperties();
                this.loadFolders();
                this.loadResults();
                this.isLoading = false;
              }
            })
            .catch((err) => {
              console.log(err);
              this.user = false;
              this.selectedUser = null;
              this.companies = false;
              this.isLoading = false;
            });
        } else {
          this.user = false;
          this.selectedUser = null;                
          this.companies = false;
          this.isLoading = false;
        }
      });
    },
    login(email, password) {
      this.user = false;
      this.selectedUser = null;                
      this.errorState = false;
      this.isLoading = true;
      firebase
        .auth()
        .signInWithEmailAndPassword(email, password)
        .catch((error) => {
          this.selectedUser = null;                
          this.isLoading = false;
          this.errorState = error;
        });
    },
    logout() {
      this.user = null;
      this.selectedUser = null;                
      this.$cookie.set("uuid", "");
      this.locked = false;
      this.isLPCATRunning = false;
      this.errorState = false;
      firebase
        .auth()
        .signOut()
        .then(() => (this.user = false))
        .catch((error) => {
          this.errorState = error;
        });
    },

    // View states
    isLoaderVisible() {
      return this.isLoading;
    },
    isLoginVisible() {
      return !this.isLoading && !this.user;
    },
    isPortalVisible() {
      return (
        this.user && !this.isLoading && !this.isLocked && !this.isLPCATRunning
      );
    },
    isLPCATVisible() {
      return !this.isLoading && this.isLPCATRunning;
    },
    isPINVisibile() {
      return !this.isLoading && this.isLocked && !this.isLPCATRunning;
    },
    isLPCATResultVisible() {
      return !this.isLoading && this.result !== null;
    },
    showPortal() {
      this.isLPCATRunning = false;
    },
    // Company
    loadProperties() {
      firestore.doc(`/users/${this.user.email}`).onSnapshot((doc) => {
        this.runs = doc.data().runs;
      });
    },

    // Folders
    loadFolders() {
      firestore
        .collection("companies")
        .doc(this.user.company)
        .collection("users")
        .doc(this.user.email)
        .collection("folders")
        .onSnapshot((querySnapshot) => {
          const folders = {};
          querySnapshot.forEach((doc) => (folders[doc.id] = doc.data()));
          this.folders = folders;
        });
    },
    addFolder() {
      var folderName = prompt("Folder name:", "");
      if (folderName) {
        const name = folderName;
        const parent =
          this.selectedFolderId !== null ? this.selectedFolderId : "";
        const timestamp = new Date().valueOf();
        const folderId = `${folderName}___${timestamp}`.replace(
          /[^0-9a-zA-Z_-]/g,
          ""
        );

        this.errorState = false;
        this.isFolderActionBusy = true;
        firestore
          .collection("companies")
          .doc(this.user.company)
          .collection("users")
          .doc(this.user.email)
          .collection("folders")
          .doc(folderId)
          .set({ name, parent })
          .then(() => (this.isFolderActionBusy = false))
          .catch((error) => {
            this.errorState = error;
          });
      }
    },
    trashFolder() {
      let folderId = this.selectedFolderId;

      if (!folderId || folderId === "") {
        return;
      }

      firestore
        .collection("companies")
        .doc(this.user.company)
        .collection("users")
        .doc(this.user.email)
        .collection("folders")
        .doc(folderId)
        .update({ parent: "trash" });
    },
    deleteFolder(id) {
      let folderId = id || this.selectedFolderId;

      if (!folderId || folderId === "") {
        return;
      }

      firestore
        .collection("companies")
        .doc(this.user.company)
        .collection("users")
        .doc(this.user.email)
        .collection("folders")
        .where("parent", "==", folderId)
        .get()
        .then((querySnapshot) =>
          querySnapshot.forEach((doc) => this.deleteFolder(doc.id))
        );

      firestore
        .collection("companies")
        .doc(this.user.company)
        .collection("users")
        .doc(this.user.email)
        .collection("results")
        .where("folder", "==", folderId)
        .get()
        .then((querySnapshot) =>
          querySnapshot.forEach((doc) => doc.ref.update({ folder: "deleted" }))
        );

      firestore
        .collection("companies")
        .doc(this.user.company)
        .collection("users")
        .doc(this.user.email)
        .collection("folders")
        .doc(folderId)
        .delete();
    },
    selectFolder(selectedFolderId, selectedFolderName, isTrash, isShared, isLinked, isVoucher, email, company) {
      this.selectedFolderId = selectedFolderId;
      this.selectedFolderName = selectedFolderName;
      this.isTrash = isTrash;
      this.isShared = isShared;
      this.isLinked = isLinked;
      this.isVoucher = isVoucher;
      this.selectedUser = {
        admin: this.user.admin,                  
        email: email ? email : this.user.email,
        company: company ? company : this.user.company
      };
      this.loadResults();
    },

    // Results
    loadResults() {
      const parent =
        this.selectedFolderId !== null ? this.selectedFolderId : "";
      this.searchText = "";

      firestore
        .collection("companies")
        .doc(this.selectedUser.company)
        .collection("users")
        .doc(this.selectedUser.email)
        .collection("results")
        .where("folder", "==", parent)
        .orderBy(this.sort, this.asc ? "asc" : "desc")
        .onSnapshot((querySnapshot) => {
          const results = {};
          querySnapshot.forEach((doc) => {
            results[doc.id] = doc.data();
          });
          this.results = results;
        });
    },
    searchResults(searchText) {
      const search = searchText.toLowerCase();
      this.searchText = searchText;
      firestore
        .collection("companies")
        .doc(this.user.company)
        .collection("users")
        .doc(this.user.email)
        .collection("results")
        .orderBy(this.sort, this.asc ? "asc" : "desc")
        .onSnapshot((querySnapshot) => {
          const results = {};
          querySnapshot.forEach((doc) => {
            let match =
              doc.data().folder !== "deleted" &&
              (doc
                .data()
                .firstname.toLowerCase()
                .includes(search) ||
                doc
                  .data()
                  .lastname.toLowerCase()
                  .includes(search) ||
                doc
                  .data()
                  .idnumber.toLowerCase()
                  .includes(search));

            if (match) {
              results[doc.id] = doc.data();
            }
          });
          this.results = results;
        });
    },
    saveLPCATResult(result) {
      const folder =
        this.selectedFolderId !== null ? this.selectedFolderId : "";
      result.folder = folder;
      this.errorState = false;
      this.isResultActionBusy = true;
      this.saveCall({
        company: this.user.company,
        folder,
        result,
      })
        .then(() => {
          this.isResultActionBusy = false;
        })
        .catch((error) => {
          this.errorState = error;
        });
    },
    moveResultToTrash(resultId) {
      if (!resultId || resultId === "") {
        return;
      }

      firestore
        .collection("companies")
        .doc(this.user.company)
        .collection("users")
        .doc(this.user.email)
        .collection("results")
        .doc(resultId)
        .update({ folder: "trash" });
    },
    deleteResult(resultId) {
      firestore
        .collection("companies")
        .doc(this.user.company)
        .collection("users")
        .doc(this.user.email)
        .collection("results")
        .doc(resultId)
        .update({ folder: "deleted" });
    },
    emptyTrash() {
      if (confirm("Are you sure?")) {
        this.selectFolder("trash");
        this.deleteFolder("trash");
      }
    },
    selectResult(selectedResultId) {
      this.selectedResultId = selectedResultId;
    },
    viewResult() {
      firestore
        .collection("companies")
        .doc(this.selectedUser.company)
        .collection("users")
        .doc(this.selectedUser.email)
        .collection("results")
        .doc(this.selectedResultId)
        .get()
        .then((doc) => {
          this.result = doc.exists ? doc.data() : null;
        })
        .catch(() => {
          this.result = null;
        });
    },
    importLegacyResults(filename, file) {
      return this.importLegacyCall({
        folder: this.selectedFolderId,
        company: this.user.company,
        file,
      });
    },
    closeResult() {
      this.result = null;
      this.selectedResultId = null;
    },
    sortColumns(sort, asc) {
      this.sort = sort;
      this.asc = asc;
      this.$cookie.set("sort", sort);
      this.$cookie.set("asc", asc);
      this.loadResults();
    },
    keyUpHandler(e) {
      if (e.keyCode === 27) {
        this.closeResult();
      }
    },

    // Tests
    startLPCAT() {
      this.isLocked = true;
      this.isLPCATRunning = true;
    },
    startCPT() {
      // There is no spoon ... yet
    },

    // Pin
    pinUnlock() {
      this.isLocked = false;
      this.$cookie.set("uuid", "");
    },
    pinCancel() {
      this.isLPCATRunning = true;
    },
  },
};
</script>

<style>
@import url("../node_modules/font-awesome/css/font-awesome.min.css");

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
font,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  vertical-align: baseline;
  font-weight: inherit;
  font-size: 100%;
  font-variant: inherit;
  font-style: inherit;
}
:focus {
  outline: 0;
}
html {
  height: 100%;
}
body {
  height: 100%;
  line-height: 1;
  color: black;
}
ol,
ul {
  list-style: inside;
  list-style-type: circle;
}
table {
  border-collapse: separate;
  border-spacing: 0;
}
caption,
th,
td {
  text-align: left;
  font-weight: normal;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
}
blockquote,
q {
  quotes: "" "";
}

/* Global */

html {
  -webkit-font-smoothing: antialiased;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

*,
*:before,
*:after {
  -webkit-box-sizing: inherit;
  -moz-box-sizing: inherit;
  box-sizing: inherit;
}

#app {
  height: 100%;
}

@media print {
  body,
  html,
  #app {
    height: auto;
  }
}

.caret {
  color: #96a3b0 !important;
  margin-left: 12px;
  margin-right: 12px;
  font-size: 14px;
}
</style>
