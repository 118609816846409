<template>
  <div class="folder">
    <div class="folder-name" :class="{ selected: isSelected() }">
      <div class="menu" v-if="!isShared && !isLinked">
        <a @click="addFolder" title="Add Folder"><i class="fa fa-plus"></i></a>
        <a @click="deleteFolder" v-if="!hasTrashRoot()" title="Move to Trash"><i class="fa fa-trash"></i></a>
        <a @click="deleteFolder" v-if="hasTrashRoot()" title="Delete Folder"><i class="fa fa-remove"></i></a>
      </div>
      <div class="folder-name-container" @click="toggleFolder" >
        <i v-if="open" class="fa fa-folder-open"></i>
        <i v-else class="fa fa-folder"></i>
        {{folder.name}}
      </div>
    </div>
    <div v-if="open" class="children">
      <Folder 
        :folder="folder" 
        :folders="folders" 
        :id="folderId" 
        :key="folderId" 
        :selectedFolderId="selectedFolderId"
        :isTrash="isTrash"
        :isShared="isShared"
        :isLinked="isLinked"
        :isVoucher="isVoucher"
        v-for="(folder, folderId) in directChildFolders"
        @add-folder="addFolder"
        @delete-folder="deleteFolder"
        @select-folder="selectFolder"/>
      </div>
  </div>
</template>

<script>
export default {
  name: 'Folder',
  data: function () {
    return {
      selected: false,
      open: false
    }
  },
  props: {
    id: String,
    selectedFolderId: String,
    folder: Object,
    folders: Object,
    isTrash: Boolean,
    isShared: Boolean,
    isLinked: Boolean,
    isVoucher: Boolean,
  },
  computed: {
    directChildFolders: function () {
      let children = {}
      let parent = this.id
      let folders = this.folders
      Object.keys(folders).forEach(function(folderId) {
          let folder = folders[folderId]
          if (folder.parent === parent) {
            children[folderId] = folder
          }
      });
      return children
    }
  },
  methods: {
    folderName () {
      return this.folder.name
    },
    isChild (parentId, folder) {
      return folder.parent === parentId
    },
    hasTrashRoot () {
      if (this.folder.parent === 'trash') {
        return true
      }

      let hasParent = typeof this.$parent.hasTrashRoot === "function"

      if (hasParent) {
        return this.$parent.hasTrashRoot()
      }

      return false
    },
    addFolder () {
      this.$emit('add-folder')
    },
    deleteFolder () {
      this.$emit('delete-folder')
    },
    selectFolder (id, folderName, isTrash, isShared, isLinked, isVoucher) {
      this.$emit('select-folder', id, folderName, isTrash, isShared, isLinked, isVoucher)
    },
    toggleFolder () {
      let caret = '<i class="fa fa-angle-double-right caret"></i>'
      let folderName = typeof this.$parent.folderName === "function"
          ? caret + this.$parent.folderName() + caret + this.folderName()
          : caret + this.folder.name

      this.selectFolder(this.id, folderName, 
        this.isTrash,
        this.isShared,
        this.isLinked,
        this.isVoucher)


      if ((this.isSelected() && this.open) || !this.open) {
        this.open = !this.open
      }
    },
    isSelected () {
      return this.id === this.selectedFolderId
    }
  },
}
</script>

<style scoped>
  .folder {
    cursor: pointer;
    color: #c3c3c3;
    min-height: 29px;
    line-height: 29px;
  }

  .folder-name {
    padding-bottom: 8px;
    margin-bottom: 8px;
    border-bottom: 1px solid #444;
  }

  .folder-name:hover {
    color: white;
  }

  .folder-name i {
    width: 20px;
  }

  .folder-name-container {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .selected {
    color: yellow !important;
  }

  .selected  .menu {
    opacity: 1;
  }

  .children {
    margin-left: 28px;
  }

  .menu {
    display: inline-block;
    opacity: 0;
    float: right;
    margin-left: 16px;
    margin-right: 0px;
    margin-top: -2px;
    padding: 2px 8px;
    color: white;
    background-color: #3e3e3e;
    font-size: 14px;
    border-radius: 2px;
    border: none;
    cursor: pointer;
    -webkit-box-shadow: 0px 0px 2px 0px rgba(0,0,0,0.2);
    -moz-box-shadow: 0px 0px 2px 0px rgba(0,0,0,0.2);
    box-shadow: 0px 0px 2px 0px rgba(0,0,0,0.2);
    transition: opacity 0.3s;
  }

  .menu a:first-child {
    padding-left: 0px;
    margin-left: 4px;
    border-left: none;
    font-size: 16px;
  }

  .menu a {
    padding-left: 12px;
    margin-left: 8px;
    border-left: 1px solid #5e5e5e;
    font-size: 16px;
  }

  .menu a:hover {
    color: yellow;
  }
</style>
