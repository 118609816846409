<template>
  <div class="container">
    <div class="upload-box">
      <div class="upload-body">
        <h1><i class="fa fa-upload space"></i> Upload Results</h1>
        <div v-if="uploadedFiles.length == 0" class="upload-location">
          <i class="fa fa-files-o"></i>
          drag files to upload here
        </div>
        <div v-if="uploadedFiles.length == 1" class="upload-location">
          <i class="fa fa-file-o"></i>
          {{uploadedFiles.length}} file ready for upload
        </div>
        <div v-if="uploadedFiles.length > 1" class="upload-location">
          <i class="fa fa-files-o"></i>
          {{uploadedFiles.length}} files ready for upload
        </div>
      </div>
      <div class="upload-footer">
        <button v-if="!isImporting" class="ok" @click="post()" :disabled="uploadedFiles.length == 0">Upload</button>
        <button v-if="isImporting" class="ok" :disabled="true">Uploading ... <i class="fa fa-spinner fa-spin"></i></button>
        <button class="cancel" @click="cancel()">Cancel</button>
      </div>
    </div>
    <div class="glass" @keyup.esc="cancel" @click="cancel">
    </div>
  </div>
</template>

<script>

export default {
  name: 'Upload',
  data: () => {
    return {
      isImporting: false,
      selected: false,
      open: false,
      uploadedFiles: [],
      uploadStatus: []
    }
  },
  props: {
    company: [Boolean, String],
    selectedFolderId: String,
    toggleUpload: Function,
    importLegacyResults: Function
  },
  methods: {
    showFeedback() {
      if (this.uploadStatus.length === this.uploadedFiles.length) {
        console.log(this.uploadStatus)
        this.isImporting = false
        this.toggleUpload()
      }
    },
    cancel () {
      this.uploadStatus = [];
      this.toggleUpload()
    },
    post () {
      this.uploadStatus = [];
      for(var i = 0; i < this.uploadedFiles.length; i++) {
        this.isImporting = true
        var file = this.uploadedFiles[i]
        var reader = new FileReader()
        reader.onload = e =>
          this.importLegacyResults(file.name, e.target.result)
            .then(status => {
              this.uploadStatus.push({
                filename: file.name,
                status,
                success: true
              })
            })
            .catch(err => {
              this.uploadStatus.push({
                filename: file.name,
                status: err,
                success: false
              })
            })
            .then(() => this.showFeedback())

        reader.readAsText(file, "ISO-8859-1")
      }
    },
  },
  mounted() {
      if (typeof window.drop == "function") return;

      window.addEventListener("dragenter", () => {
        document.querySelector(".upload-location").style.fontSize = "18px";
        document.querySelector(".upload-location").style.color = "#7E8DA0";
        document.querySelector(".upload-location").style.borderColor = "#7E8DA0";
      });

      window.addEventListener("dragleave", (e) => {
        e.preventDefault();
        document.querySelector(".upload-location").style.fontSize = "16px";
        document.querySelector(".upload-location").style.color = "#A1ACBA";
        document.querySelector(".upload-location").style.borderColor = "#B9C1CB";
      });

      window.addEventListener("dragover", (e) => {
        e.preventDefault();
        document.querySelector(".upload-location").style.fontSize = "18px";
        document.querySelector(".upload-location").style.color = "#7E8DA0";
        document.querySelector(".upload-location").style.borderColor = "#7E8DA0";
      });

      window.addEventListener("drop", (e) => {
          e.preventDefault()
          document.querySelector(".upload-location").style.fontSize = "16px"
          document.querySelector(".upload-location").style.color = "#A1ACBA"
          document.querySelector(".upload-location").style.borderColor = "#B9C1CB"

          var len = e.dataTransfer.files.length
          for(var i = 0; i < len; i++) {
            this.uploadedFiles.push(e.dataTransfer.files[i])
          }
      });
  }
}
</script>

<style scoped>
  .container {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }

  .glass {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: black;
    opacity: 0.4;
  }

  .upload-box {
    position: fixed;
    left: 50%;
    top: 50%;
    margin-left: -240px;
    margin-top: -180px;
    width: 480px;
    height: 360px;
    background: #E8EBED;
    border-radius: 4px;
    z-index: 99999;
    -webkit-box-shadow: 1px 1px 4px 0px rgba(0,0,0,0.2);
    -moz-box-shadow: 1px 1px 4px 0px rgba(0,0,0,0.2);
    box-shadow: 1px 1px 4px 0px rgba(0,0,0,0.2);
  }

  .upload-box h1 {
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    font-size: 16px;
    padding: 18px 16px;
    height: 61px;
    color: #353D46;
    background-color: #DCE0E5;
    border-bottom: 1px solid #D0D6DC;
  }

  .upload-location {
    position: absolute;
    top: 60px;
    bottom: 60px;
    left: 0;
    right: 0;
    margin: 16px;
    padding-top: 60px;
    color: #A1ACBA;
    border: 1px dashed #B9C1CB;
    border-radius: 8px;
    line-height: 30px;
    text-align: center;
    cursor: pointer;
    transition: border-color 0.3s, font-size 0.3s, color 0.3s;
  }

  .upload-location i {
    display: block;
    font-size: 36px;
    margin-bottom: 20px;
  }

  .upload-footer {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    padding: 18px 16px;
    height: 61px;
    border-top: 1px solid #D0D6DC;
    background-color: #DCE0E5;
  }

  .space {
    margin-right: 8px;
  }

  button {
    float: right;
    margin-top:-4px;
    margin-bottom: -5px;
    margin-left: 8px;
    padding: 8px 12px;
    color: white;
    background: #95A2B1;
    font-size: 14px;
    border-radius: 2px;
    border: none;
    cursor: pointer;
    -webkit-box-shadow: 0px 0px 1px 0px rgba(0,0,0,0.9);
    -moz-box-shadow: 0px 0px 1px 0px rgba(0,0,0,0.9);
    box-shadow: 0px 0px 1px 0px rgba(0,0,0,0.9);
  }

  button:hover {
    background: #A1ACBA;
  }

  .ok {
    background-color: #00a1cb;
  }

  .ok:hover {
    background-color: #00B4E0;
  }

</style>
