<template>
  <div>
    <div class="header" v-if="isHome">
      <i class="fa fa-home space"></i> Results<span v-html="selectedFolderName"></span>
    </div>
    <div class="header" v-if="isTrash">
      <i class="fa fa-trash space"></i> Trash<span v-html="selectedFolderName"></span>
      <button @click="emptyTrash" class="empty">Empty Trash <i class="fa fa-remove"></i></button>
    </div>
    <div class="header" v-if="isSearch">
      <i class="fa fa-search space"></i> Search results for <b><i>"{{searchInput}}"</i></b>
      <button @click="clearSearch" class="clear">Clear Search <i class="fa fa-remove"></i></button>
    </div>
    <div class="header" v-if="isShared">
      <i class="fa fa-users space"></i> Shared<span v-html="selectedFolderName"></span>
    </div>
    <div class="header" v-if="isLinked">
      <i class="fa fa-spy space"></i> Linked<span v-html="selectedFolderName"></span>
    </div>
    <div class="header" v-if="isVoucher">
      <i class="fa fa-voucher space"></i> Voucher<span v-html="selectedFolderName"></span>
      <a v-if="isVoucher" class="icon-right" title="Unlink voucher" @click="unlinkVoucher"><i class="fa fa-unlink"></i></a>
      <a v-if="!isVoucher" class="icon-right" title="Create voucher" @click="createVoucher"><i class="fa fa-ticket"></i></a>
    </div>

    <div class="columns">
      <div class="column-icon"></div>
      <div class="column-name" @click="nameSort()">
        Candidate Name
        <i class="fa fa-long-arrow-down" v-if="sort === 'lastname' && asc"></i>
        <i class="fa fa-long-arrow-up" v-if="sort === 'lastname' && !asc"></i>
      </div>
      <div class="column-id" @click="idSort()">
        Identification
        <i class="fa fa-long-arrow-down" v-if="sort === 'idnumber' && asc"></i>
        <i class="fa fa-long-arrow-up" v-if="sort === 'idnumber' && !asc"></i>
      </div>
      <div class="column-date" @click="dateSort()">
        Test Date
        <i class="fa fa-long-arrow-down" v-if="sort === 'created' && asc"></i>
        <i class="fa fa-long-arrow-up" v-if="sort === 'created' && !asc"></i>
      </div>
      <div class="column-options">
      </div>
    </div>
    <div class="results-container">
      <div class="results-container-inner">
        <Result
          :user="user"
          :result="result"
          :id="resultId"
          :key="resultId"
          :selectedResultId="selectedResultId"
          :isShared="isShared"
          :isLinked="isLinked"
          v-for="(result, resultId) in results"
          @delete-result="deleteResult"
          @empty-trash="emptyTrash"
          @move-result-to-trash="moveResultToTrash"
          @select-result="selectResult"
          @view-result="viewResult"
        />
      </div>
    </div>
  </div>
</template>


<script>

import Result from './Result.vue'

export default {
  name: 'Results',
  components: {
    Result
  },
  props: {
    user: Object,
    results: Object,
    selectedResultId: String,
    selectedFolderName: String,
    searchInput: String,
    sort: String,
    asc: Boolean,
    isTrash: Boolean,
    isShared: Boolean,
    isLinked: Boolean,
    isVoucher: Boolean,
  },
  computed: {
    isHome: function() {
      return !this.isTrash && !this.isShared && !this.isLinked && !this.isvoucher && this.searchInput === '';
    },
    isSearch: function() {
      return !this.isTrash && !this.isShared && !this.isLinked && !this.isvoucher && this.searchInput !== '';
    }
  },
  methods: {
    deleteResult(selectedResultId) {
      this.$emit('delete-result', selectedResultId)
    },
    moveResultToTrash(selectedResultId) {
      this.$emit('move-result-to-trash', selectedResultId)
    },
    emptyTrash() {
      this.$emit('empty-trash')
    },
    selectResult(selectedResultId) {
      this.$emit('select-result', selectedResultId)
    },
    viewResult() {
      this.$emit('view-result')
    },
    startLPCAT() {
      this.$emit('start-lpcat')
    },
    nameSort() {
      this.$emit('sort', 'lastname', !this.asc)
    },
    idSort() {
      this.$emit('sort', 'idnumber', !this.asc)
    },
    dateSort() {
      this.$emit('sort', 'created', !this.asc)
    },
    clearSearch() {
      this.$emit('clear-search')
    },
    createVoucher() {

    },
    unlinkVoucher() {

    }
  }
}
</script>

<style scoped>
  .space {
    margin-right: 8px !important;
  }

  .caret {
    color: red;
    margin-left: 8px !important;
    margin-right: 8px !important;
  }

  .results-container {
    position: relative;
    flex: 1;
    padding: 0px;
    margin: 0px;
    -webkit-box-shadow: inset 2px 2px 3px 0px rgba(0,0,0,0.1);
    -moz-box-shadow: inset 2px 2px 3px 0px rgba(0,0,0,0.1);
    box-shadow: inset 2px 2px 3px 0px rgba(0,0,0,0.1);
  }

  .results-container-inner {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    overflow: scroll;
  }

  .header {
    font-size: 18px;
    padding: 18px 16px;
    height: 61px;
    color: #353D46;
    background-color: #DCE0E5;
  }

  .columns {
    display: flex;
    border-top: 1px solid #F3F5F6;
    border-bottom: 1px solid #D0D6DC;
    color: #4F5C69;
    background-color: #E8EBED;
    height: 28px;
    line-height: 26px;
    font-size: 10px;
    font-weight: bold;
    text-transform: uppercase;
  }

  .columns > div {
    padding-left: 4px;
    height: 26px;
    cursor: pointer;
    border-left: 1px solid #DCE0E5;
  }

  .columns > div > i {
    color: #697A8C;
    margin-left: 4px;
  }

  .column-icon {
    width: 40px;
    text-align: center;
    border-left: none !important;
  }

  .column-name {
    flex-grow: 0.65;
    flex-basis: 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    border-left: 1px solid transparent;
  }

  .column-name:hover {
    background-color: #DDE1E4;
  }

  .column-id {
    flex-grow: 0.35;
    flex-basis: 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .column-id:hover {
    background-color: #DDE1E4;
  }

  .column-date {
    width: 150px;
  }

  .column-date:hover {
    background-color: #DDE1E4;
  }

  .column-options {
    width: 98px;
  }

  .clear {
    float: right;
    height: 40px;
    margin-top: -8px;
    margin-bottom: -8px;
    padding: 8px 12px;
    color: white;
    background-color: #00a1cb;
    font-size: 14px;
    border-radius: 2px;
    border: none;
    cursor: pointer;
    -webkit-box-shadow: 0px 0px 2px 0px rgba(0,0,0,0.4);
    -moz-box-shadow: 0px 0px 2px 0px rgba(0,0,0,0.4);
    box-shadow: 0px 0px 2px 0px rgba(0,0,0,0.4);
  }

  .clear i {
    padding-left: 12px;
    margin-left: 8px;
    font-size: 16px;
    border-left: 1px solid #85E7FF;
  }

  .clear:hover {
    background-color: #00B4E0;
  }

  .empty {
    float: right;
    height: 40px;
    margin-top: -8px;
    margin-bottom: -8px;
    padding: 8px 12px;
    color: white;
    background-color: #E54028;
    font-size: 14px;
    border-radius: 2px;
    border: none;
    cursor: pointer;
    -webkit-box-shadow: 0px 0px 2px 0px rgba(0,0,0,0.4);
    -moz-box-shadow: 0px 0px 2px 0px rgba(0,0,0,0.4);
    box-shadow: 0px 0px 2px 0px rgba(0,0,0,0.4);
  }

  .empty i {
    padding-left: 12px;
    margin-left: 8px;
    font-size: 16px;
    border-left: 1px solid #EB6F5C;
  }

  .empty:hover {
    background-color: #E64F37;
  }

  .icon-right {
    float: right;
    cursor: pointer;
  }
</style>
