import firebase from 'firebase/app';

import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/functions';

const config = {
  apiKey: "AIzaSyAQtvvA2GqODzoNKdKwDuHqvZiQTckepqU",
  authDomain: "mminitiatives-93166.firebaseapp.com",
  databaseURL: "https://mminitiatives-93166.firebaseio.com",
  projectId: "mminitiatives-93166",
  storageBucket: "mminitiatives-93166.appspot.com",
  messagingSenderId: "510170836126",
  appId: "1:510170836126:web:6b01960b827b1531"
};

firebase.initializeApp(config);

export default firebase;
