<template>
  <div
    class="result"
    @dblclick="viewResult"
    @click="selectResult"
    :class="{ selected: isSelected('') }"
  >
    <div class="result-icon">
      <i class="fa fa-circle green" v-if="!result.processed"></i>
      <i class="fa fa-check-circle green" v-if="result.processed"></i>
    </div>
    <div class="result-name">{{ result.firstname }} {{ result.lastname }}</div>
    <div class="result-id">{{ result.idnumber }}</div>
    <div class="result-date">{{ localTime(result.created) }}</div>
    <div class="result-options">
      <div class="options-box">
        <button class="process" @click="processResult" v-if="isNotProcessed()">
          <i v-if="this.hasProcessError" class="fa fa-warning"></i>
          <i v-if="!this.hasProcessError" class="fa fa-gear"></i>
        </button>
        <button
          class="processing"
          @click="processResult"
          v-if="isProcessing()"
          disabled
        >
          <i class="fa fa-cog fa-spin"></i>
        </button>
        <button class="view" @click="viewResult" v-if="isProcessed()">
          <i class="fa fa-external-link"></i>
        </button>
        <button
          class="delete"
          :disabled="isProcessing()"
          @click="deleteResult"
          v-if="!isShared && !isLinked && isTrash(result)"
        >
          <i class="fa fa-remove"></i>
        </button>
        <button
          class="delete"
          :disabled="isProcessing()"
          @click="moveResultToTrash"
          v-if="!isShared && !isLinked && !isTrash(result)"
        >
          <i class="fa fa-trash"></i>
        </button>
      </div>
    </div>
  </div>
</template>

<script>

import firebase from "../../../firebase";
import moment from "moment/src/moment";

const functions = firebase.functions();

export default {
  name: "Result",
  props: {
    id: String,
    user: Object,
    selectedResultId: String,
    result: Object,    
    isShared: Boolean,
    isLinked: Boolean
  },
  data() {
    return {
      isBusyProcessing: false,
      hasProcessError: false,
      processCall: functions.httpsCallable("process"),
    };
  },
  methods: {
    isProcessed() {
      if (this.result.processed) {
        this.isBusyProcessing = false;
      }
      return this.result.processed;
    },
    isNotProcessed() {
      return !this.result.processed && !this.isBusyProcessing;
    },
    isProcessing() {
      return !this.result.processed && this.isBusyProcessing;
    },
    isSelected() {
      return this.id === this.selectedResultId;
    },
    isTrash(result) {
      return result.folder === "trash";
    },
    deleteResult() {
      this.$emit("delete-result", this.selectedResultId);
    },
    moveResultToTrash() {
      this.$emit("move-result-to-trash", this.selectedResultId);
    },
    selectResult() {
      this.$emit("select-result", this.id);
    },
    viewResult() {
      this.$emit("view-result", this.id);
    },
    processResult() {
      this.isBusyProcessing = true;
      this.hasProcessError = false;
      this.processCall({
        company: this.user.company,
        email: this.user.email,
        result: this.id,
      })
        .then(() => {
          this.isBusyProcessing = true;
          this.hasProcessError = false;
        })
        .catch((e) => {
          console.log(e);
          this.isBusyProcessing = false;
          this.hasProcessError = true;
          alert("Unable to process result.");
        });
    },
    localTime(created) {
      return moment(created).format("DD MMM YYYY HH:mm");
    },
  },
};
</script>

<style scoped>
.result {
  display: flex;
  height: 47px;
  line-height: 46px;
  color: #555;
  border-top: 1px solid #f3f5f6;
  cursor: pointer;
}

.result > div {
  padding-left: 4px;
  background-color: transparent;
  border-left: 1px solid transparent;
}

.result:first-child {
  border-top: none;
}

.result:hover {
  background-color: rgba(255, 255, 0, 0.1);
}

.result-name {
  flex-grow: 0.65;
  flex-basis: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-left: 0px !important;
}

.result-icon {
  width: 44px;
  font-size: 16px;
  text-align: center;
  border-left: none !important;
}

.result-id {
  flex-grow: 0.35;
  flex-basis: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.result-date {
  width: 150px;
}

.selected .options-box {
  opacity: 1;
}

.result-options {
  width: 98px;
  padding-right: 8px;
  overflow: hidden;
  text-align: right;
}

.options-box {
  opacity: 0;
  transition: opacity 0.1s;
}

.green {
  color: #61ae24;
}

.orange {
  color: #f18d05;
}

span {
  float: right;
}

button {
  margin-left: 8px;
  padding: 8px 0px;
  text-align: center;
  width: 36px;
  color: white;
  font-size: 14px;
  border-radius: 2px;
  border: none;
  cursor: pointer;
  -webkit-box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.2);
  box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.2);
}

button:first-child {
  margin-left: 0px;
}

.view {
  background-color: #00a1cb;
}

.view:hover {
  background-color: #00b4e0;
}

/* .view i {
    padding-left: 12px;
    margin-left: 8px;
    border-left: 1px solid #85E7FF;
    font-size: 16px;
  } */

.process {
  background-color: #00a1cb;
}

.process:hover {
  background-color: #00b4e0;
}

/* .process i {
    padding-left: 12px;
    margin-left: 8px;
    border-left: 1px solid #FCC373;
    font-size: 16px;
  } */

.delete {
  background-color: #e54028;
}

.delete:hover {
  background-color: #e64f37;
}

.delete:disabled {
  opacity: 0.2;
}

/* .delete i {
    padding-left: 12px;
    margin-left: 8px;
    border-left: 1px solid #EB6F5C;
    font-size: 16px;
  } */

.processing {
  background-color: #00b4e0;
}
</style>
