<template>
	<div class="loader" v-bind:class="{ active: isLoading, animated: isAnimated, started: hasStarted }">
		<div class="black">
		</div>
		<div class="green">
		</div>
		<div class="spinner-wave">
			<div></div>
			<div></div>
			<div></div>
			<div></div>
			<div></div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'Loader',
	props: {
		isLoading: Boolean,
	},
	data () {
		return {
			isAnimated: false,
			isStarted: false
		}
	},
	mounted () {
		this.isAnimated = true
	},
	methods: {
		hasStarted: () => {
			if (!this.isLoading) {
				this.isStarted = true
			}

			return this.isStarted
		},
  }
}
</script>

<style scoped>
	.loader {
		position: fixed;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		pointer-events: none;
		line-height: 100%;
		height: 100%;
		z-index:9999999;
	}

	.loader.active {
		pointer-events: auto;
	}

	.green {
		position: fixed;
		height: 0%;
		opacity: 1;
		bottom: 0;
		left: 0;
		right: 0;
		background: #61ae24;
	}

	.animated .green {
		transition: height 1s ease-out, opacity 2s ease-out;
	}

	.active .green {
		height: 100%;
		opacity: 1;
	}

	.started .green {
		display: none;
	}

	.black {
		position: fixed;
		opacity: 0;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		background: #303030;
	}

	.animated .black {
		transition: opacity 2s ease-out;
	}

	.active .black {
		opacity: 1;
	}

	.spinner-wave {
			margin: 25% auto;
			width: 140px;
			height: 70px;
			text-align: center;
			opacity: 0;
	}

	.animated .spinner-wave {
			transition: opacity 1s ease-out;
	}

	.active .spinner-wave {
		opacity: 1;
	}

	.spinner-wave > div {
			background-color: white;
			height: 100%;
			width: 12px;
			margin: 3px;
			border-radius: 50px;
			display: inline-block;

			-webkit-animation: wave 1.6s infinite ease-in-out;
			-moz-animation: wave 1.6s infinite ease-in-out;
			-o-animation: wave 1.6s infinite ease-in-out;
			-ms-animation: wave 1.6s infinite ease-in-out;
			animation: wave 1.6s infinite ease-in-out;
	}

	.spinner-wave div:nth-child(2) {
			-webkit-animation-delay: -1.4s;
			-moz-animation-delay: -1.4s;
		-ms-animation-delay: -1.4s;
		-o-animation-delay: -1.4s;
			animation-delay: -1.4s;
	}

	.spinner-wave div:nth-child(3) {
			-webkit-animation-delay: -1.2s;
			-moz-animation-delay: -1.2s;
		-ms-animation-delay: -1.2s;
		-o-animation-delay: -1.2s;
			animation-delay: -1.2s;
	}

	.spinner-wave div:nth-child(4) {
			-webkit-animation-delay: -1.0s;
			-moz-animation-delay: -1.0s;
		-ms-animation-delay: -1.0s;
		-o-animation-delay: -1.0s;
			animation-delay: -1.0s;
	}

	.spinner-wave div:nth-child(5) {
			-webkit-animation-delay: -0.8s;
			-moz-animation-delay: -0.8s;
		-ms-animation-delay: -0.8s;
		-o-animation-delay: -0.8s;
			animation-delay: -0.8s;
	}

	@-webkit-keyframes wave {
			0%, 40%, 100% { -webkit-transform: scaleY(0.4) }
			20% { -webkit-transform: scaleY(1.0) }
	}
	@-moz-keyframes wave {
			0%, 40%, 100% { transform: scaleY(0.4); }
			20% { transform: scaleY(1.0); }
	}
	@-o-keyframes wave {
			0%, 40%, 100% { transform: scaleY(0.4); }
			20% { transform: scaleY(1.0); }
	}
	@-ms-keyframes wave {
			0%, 40%, 100% { transform: scaleY(0.4); }
			20% { transform: scaleY(1.0); }
	}
	@keyframes wave {
			0%, 40%, 100% { transform: scaleY(0.4); }
			20% { transform: scaleY(1.0); }
	}
</style>
