<template>
  <div class="profile">
    <div class="glass"></div>
    <div v-if="!!info" class="popup">
      <div class="header">
        Account Information
      </div>
      <div class="row">
        <div class="icon"><i class="fa fa-at"></i></div>
        <div class="readonly" @click="readonlyNotice">{{email}}</div>
      </div>
      <div class="row">
        <div class="icon"><i class="fa fa-building"></i></div>
        <div class="readonly" @click="readonlyNotice">{{company}}</div>
      </div>
      <div class="separator"></div>
      <div class="row">
        <div class="icon"><i class="fa fa-user"></i></div>
        <div class="name"><input v-model="info.name" /></div>
      </div>
      <div class="row">
        <div class="icon"><i class="fa fa-phone"></i></div>
        <div class="name"><input v-model="info.phone"/></div>
      </div>
      <div class="row">
        <div class="icon"><i class="fa fa-home"></i></div>
        <div class="name">
          <textarea v-model="info.address"></textarea>
        </div>
      </div>
      <div class="row">
        <div class="icon"><i class="fa fa-lock"></i></div>
        <div @click="sendResetPasswordEmail" class="readonly"><a href="#">Change Password</a></div>
      </div>
      <div class="separator"></div>
      <div class="button-bar">
        <button @click="toggleProfile">Close <i class="fa fa-remove"></i></button>
        <button @click="save" class="action">Save <i class="fa fa-bookmark"></i></button>
      </div>
    </div>
  </div>
</template>

<script>

import firebase from '../../firebase'

const firestore = firebase.firestore();

export default {
  name: 'Profile',
  props: {
    email: String,
    company: String,
    toggleProfile: Function
  },
  data: () => {
    return {
      info: false,
    }
  },
  methods: {
    save() {
      if (!this.email) {
        alert('You are not logged in. Please log in again.');
        return;
      }

      if (!this.info) {
        alert('Unable to save your information');
        return;
      }

      firestore.collection('info').doc(this.email)
        .update(this.info)
        .then(() => this.toggleProfile())
        .catch(err => {
          console.log(err);
          alert('Unable to save your profile information')
        });
    },
    readonlyNotice() {
      alert('Contact M&M Initiative support if you need to modify this information.');
    },
    sendResetPasswordEmail() {
      firebase.auth().sendPasswordResetEmail(this.email).then(() => {
        alert('We have sent you an email with a password reset link.');
      }).catch((error) => {
        console.log('password reset email not sent', error);
        alert('Password reset email send failure.');
      });
    }
  },
  mounted() {
    firestore.collection('info').doc(this.email)
      .get()
      .then(doc => {
        if (!doc.exists) {
          throw('Document not found for ' + this.email);
        }
        this.info = doc.data();
      })
      .catch(err => {
        console.log(err);
        alert('Unable to display your information. Please try again later.');
      });
  }
}
</script>

<style scoped>
  .row {
    position: relative;
    margin-bottom: 2px;
    min-height: 48px;
  }

  .separator {
    height: 16px;
  }

  .icon {
    position: absolute;
    float: left;
    width: 48px;
    height: 100%;
    line-height: 48px;
    text-align: center;
    color: #888;
    background-color: #CCCCCC;
    border-top-left-radius: 2px;
    border-bottom-left-radius: 2px;
    font-size: 16px;
  }

  .readonly {
    padding: 12px 10px;
    min-height: 48px;
    margin: 0px 0px 0px 50px;
    font-size: 16px;
    color: #555;
    width: 518px;
    background: #eee;
    border: none;
    outline: none;
    resize: none;
    font-family:"Helvetica Neue", Arial, Helvetica, Geneva, sans-serif;
    font-size:16px;
    font-stretch:100%;
    font-style:normal;
    font-variant-caps:normal;
    font-variant-east-asian:normal;
    font-variant-ligatures:normal;
    font-variant-numeric:normal;
    font-weight:400;
  }

  .name {
    margin-left: 50px;
    margin-right: 0px;
    min-height: 48px;
    background-color: #f8f8f8;
    border-top-right-radius: 2px;
    border-bottom-right-radius: 2px;
    font-size: 16px;
  }

  .name:hover {
    background-color: white;
    border: none;
    -webkit-box-shadow: inset 0px 0px 4px 0px rgba(0,0,0,0.1);
    -moz-box-shadow: inset 0px 0px 4px 0px rgba(0,0,0,0.1);
    box-shadow: inset 0px 0px 4px 0px rgba(0,0,0,0.1);
  }

  .readonly a {
    border-radius: 4px;
    color: white;
    background-color: #61ae24;
    padding: 4px 6px;
    text-decoration: none;
    cursor: pointer;
  }

  .readonly a:hover {
    background-color: #87D945;
  }

  .profile {
    position: fixed;
    z-index: 1;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }

  .header {
    margin-left: 4px;
    margin-bottom: 16px;
    font-size: 18px;
    color: #43464B;
  }

  .glass {
    position: fixed;
    z-index: 2;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: black;
    opacity: 0.5;
  }

  .popup {
    position: absolute;
    z-index: 3;
    top: 50%;
    left: 50%;
    margin: -250px 0 0 -300px;
    width: 600px;
    height: 530px;
    padding: 16px;
    color: #555;
    background-color: #ddd;
    border-radius: 4px;
  }

  input, textarea {
    padding: 12px 10px;
    min-height: 48px;
    margin: 0px;
    font-size: 16px;
    color: #555;
    width: 460px;
    background: transparent;
    border: none;
    outline: none;
    resize: none;
    font-family:"Helvetica Neue", Arial, Helvetica, Geneva, sans-serif;
    font-size:16px;
    font-stretch:100%;
    font-style:normal;
    font-variant-caps:normal;
    font-variant-east-asian:normal;
    font-variant-ligatures:normal;
    font-variant-numeric:normal;
    font-weight:400;
  }

  textarea {
    height: 116px;
    line-height: 24px;
  }

  button {
    height: 40px;
    margin-left: 8px;
    padding: 8px 12px;
    color: white;
    background-color: #8e8e8e;
    font-size: 14px;
    border-radius: 2px;
    border: none;
    cursor: pointer;
    -webkit-box-shadow: 0px 0px 2px 0px rgba(0,0,0,0.2);
    -moz-box-shadow: 0px 0px 2px 0px rgba(0,0,0,0.2);
    box-shadow: 0px 0px 2px 0px rgba(0,0,0,0.2);
  }

  button:hover {
    background-color: #9e9e9e;
  }

  button i {
    padding-left: 12px;
    margin-left: 8px;
    border-left: 1px solid #aeaeae;
    font-size: 16px;
  }

  button.action {
    background-color: #61ae24;
  }

  button.action:hover {
    background-color: #87D945;
  }

  button.action i {
    border-left: 1px solid #BEEA99;
  }

  .button-bar {
    text-align: right;
    position: absolute;
    margin-bottom: 20px;
    bottom: 0px;
    left: 16px;
    right: 16px;
  }

</style>
