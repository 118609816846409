<template>
	<div class="container" v-on:keyup.esc="closeResult" @click="closeResult">

		<div class="menu">
			<button @click="printDocument" class="blue">Print / Download <i class="fa fa-print"></i></button>
			<button @click="createCsv" class="blue">Download CSV <i class="fa fa-file-excel-o"></i></button>
			<button @click="closeResult" class="right">Close <i class="fa fa-remove"></i></button>
		</div>

		<div class="result page-break" @click="closeResult">
			<div class="header">
				<img src="../../assets/img/lpcat/header.svg"/>
				<div class="address">
					<div class="spaceafter">
						<img src="../../assets/img/lpcat/logo.svg"/>
						26a Nicolson Street<br/>
						Bailey's Muckleneuk<br/>
						Pretoria<br/>
						0181<br/>
						South Africa
					</div>
					<div>
						Web: <a href="http://www.mminitiatives.com">www.mminitiatives.com</a><br/>
						Email: <a href="mailto:info@mminitiaves.com">info@mminitiaves.com</a>
					</div>
				</div>
			</div>
			<div class="block">
				<div class="col50-header-dark">
					Candidate
				</div>
				<div class="col50-header-light">
					Demographics
				</div>
				<div>
					<div class="col25-content-gray">
						Name
					</div>
					<div class="col25-content-light">
						{{result.firstname}}
					</div>
					<div class="col25-content-gray">
						Gender
					</div>
					<div class="col25-content-light">
						{{result.gender}}
					</div>
				</div>
				<div>
					<div class="col25-content-gray">
						Surname
					</div>
					<div class="col25-content-light">
						{{result.lastname}}
					</div>
					<div class="col25-content-gray">
						Age
					</div>
					<div class="col25-content-light">
						{{result.age}}&nbsp;
					</div>
				</div>
				<div>
					<div class="col25-content-gray">
						Id/personal number
					</div>
					<div class="col25-content-light">
						{{result.idnumber}} &nbsp;
					</div>
					<div class="col25-content-gray">
						Test Date
					</div>
					<div class="col25-content-light">
						{{localTime(result.created)}} &nbsp;
					</div>
				</div>
				<div>
					<div class="col25-content-gray">
						Education Level
					</div>
					<div class="col75-content-light">
						{{result.educationLevel}} &nbsp;
					</div>
				</div>
			</div>
			<div class="block">
				<div class="col100-header-dark">
					Scores
				</div>
				<div>
					<div class="col25-content-gray">
						Phase
					</div>
					<div class="col25-content-gray">
						T-Score
					</div>
					<div class="col25-content-gray">
						Stanine
					</div>
					<div class="col25-content-gray">
						Percentile
					</div>
				</div>
				<div>
					<div class="col25-content-gray">
						Pre-test
					</div>
					<div class="col25-content-light">
						{{Math.round(result.report.preTestScore)}}
					</div>
					<div class="col25-content-light">
						{{result.report.preTestStanine}}
					</div>
					<div class="col25-content-light">
						{{result.report.preTestPercentile}}
					</div>
				</div>
				<div>
					<div class="col25-content-gray">
						Post-test
					</div>
					<div class="col25-content-light">
						{{Math.round(result.report.postTestScore)}}
					</div>
					<div class="col25-content-light">
						{{result.report.postTestStanine}}
					</div>
					<div class="col25-content-light">
						{{result.report.postTestPercentile}}
					</div>
				</div>
				<div>
					<div class="col25-content-gray">
						Difference
					</div>
					<div class="col25-content-light">
						{{this.difference}}
					</div>
					<div class="col25-content-gray">
						Test Language
					</div>
					<div class="col25-content-light">
						{{testLanguage(result.language)}}
					</div>
				</div>
				<div>
					<div class="col25-content-gray">
						Composite
					</div>
					<div class="col25-content-light">
						{{this.composite}}
					</div>
					<div class="col25-content-gray">
						Language %
					</div>
					<div class="col25-content-light">
						{{result.languageScore}}&nbsp;
					</div>
				</div>
			</div>
			<div class="block">
				<div class="col100-header-dark">
					LPCAT Chart
				</div>
				<div class="col100-content-light chart-box">
					<div class="col-inner">
						<span>80</span>
						<span>70</span>
						<span>60</span>
						<span>50</span>
						<span>40</span>
						<span>30</span>
						<span>20</span>
					</div>
					<div class="col-inner-small">
						<GChart
							type="LineChart"
							:settings="{ packages: ['corechart'] }"
							:data="JSON.parse(result.report.preTestChart).map(roundValues)"
							:options="this.preTestConfig(200)"
						/>
						<div class="graph-label">Pretest</div>
					</div>
					<div class="col-inner-big">
						<GChart
							type="LineChart"
							:settings="{ packages: ['corechart'] }"
							:data="JSON.parse(result.report.postTestChart).map(roundValues)"
							:options="this.postTestConfig(200)"
						/>
						<div class="graph-label">Post-Test</div>
					</div>
				</div>
			</div>
			<div class="block">
				<div class="col100-header-dark">
					Education Level Estimations
				</div>
				<div class="col100-content-light">
					<div class="bullet-wrapper">
						<div class="bullet">
							<div class="label">
								Tertiary advanced degree
							</div>
							<i class="fa fa-circle" v-if="result.report.preTestEducationLevel === 'Tertiary advanced degree'"></i>
							<i class="fa fa-circle-o" v-else></i>
							<div class="marker left-marker" v-if="result.report.preTestEducationLevel === 'Tertiary advanced degree'">
								<div class="arrow"></div>
								<div class="text">Final Pretest</div>
							</div>
							<div class="marker gray left-marker" v-if="result.report.preTestBestEducationLevel === 'Tertiary advanced degree'">
								<div class="arrow"></div>
								<div class="text">Best Pretest</div>
							</div>
							<i class="fa fa-circle" v-if="result.report.postTestEducationLevel === 'Tertiary advanced degree'"></i>
							<i class="fa fa-circle-o" v-else></i>
							<div class="marker right-marker" v-if="result.report.postTestEducationLevel === 'Tertiary advanced degree'">
								<div class="arrow"></div>
								<div class="text">Final Post-test</div>
							</div>
							<div class="marker gray right-marker" v-if="result.report.postTestBestEducationLevel === 'Tertiary advanced degree'">
								<div class="arrow"></div>
								<div class="text">Best Post-test</div>
							</div>
						</div>
						<div class="bullet">
							<div class="label">
								Tertiary 4-year degree
							</div>
							<i class="fa fa-circle" v-if="result.report.preTestEducationLevel === 'Tertiary 4-year degree'"></i>
							<i class="fa fa-circle-o" v-else></i>
							<div class="marker left-marker" v-if="result.report.preTestEducationLevel === 'Tertiary 4-year degree'">
								<div class="arrow"></div>
								<div class="text">Final Pretest</div>
							</div>
							<div class="marker gray left-marker" v-if="result.report.preTestBestEducationLevel === 'Tertiary 4-year degree'">
								<div class="arrow"></div>
								<div class="text">Best Pretest</div>
							</div>
							<i class="fa fa-circle" v-if="result.report.postTestEducationLevel === 'Tertiary 4-year degree'"></i>
							<i class="fa fa-circle-o" v-else></i>
							<div class="marker right-marker" v-if="result.report.postTestEducationLevel === 'Tertiary 4-year degree'">
								<div class="arrow"></div>
								<div class="text">Final Post-test</div>
							</div>
							<div class="marker gray right-marker" v-if="result.report.postTestBestEducationLevel === 'Tertiary 4-year degree'">
								<div class="arrow"></div>
								<div class="text">Best Post-test</div>
							</div>
						</div>
						<div class="bullet">
							<div class="label">
								Tertiary first degree
							</div>
							<i class="fa fa-circle" v-if="result.report.preTestEducationLevel === 'Tertiary first degree'"></i>
							<i class="fa fa-circle-o" v-else></i>
							<div class="marker left-marker" v-if="result.report.preTestEducationLevel === 'Tertiary first degree'">
								<div class="arrow"></div>
								<div class="text">Final Pretest</div>
							</div>
							<div class="marker gray left-marker" v-if="result.report.preTestBestEducationLevel === 'Tertiary first degree'">
								<div class="arrow"></div>
								<div class="text">Best Pretest</div>
							</div>
							<i class="fa fa-circle" v-if="result.report.postTestEducationLevel === 'Tertiary first degree'"></i>
							<i class="fa fa-circle-o" v-else></i>
							<div class="marker right-marker" v-if="result.report.postTestEducationLevel === 'Tertiary first degree'">
								<div class="arrow"></div>
								<div class="text">Final Post-test</div>
							</div>
							<div class="marker gray right-marker" v-if="result.report.postTestBestEducationLevel === 'Tertiary first degree'">
								<div class="arrow"></div>
								<div class="text">Best Post-test</div>
							</div>
						</div>
						<div class="bullet">
							<div class="label">
								Tertiary diploma
							</div>
							<i class="fa fa-circle" v-if="result.report.preTestEducationLevel === 'Tertiary diploma'"></i>
							<i class="fa fa-circle-o" v-else></i>
							<div class="marker left-marker" v-if="result.report.preTestEducationLevel === 'Tertiary diploma'">
								<div class="arrow"></div>
								<div class="text">Final Pretest</div>
							</div>
							<div class="marker gray left-marker" v-if="result.report.preTestBestEducationLevel === 'Tertiary diploma'">
								<div class="arrow"></div>
								<div class="text">Best Pretest</div>
							</div>
							<i class="fa fa-circle" v-if="result.report.postTestEducationLevel === 'Tertiary diploma'"></i>
							<i class="fa fa-circle-o" v-else></i>
							<div class="marker right-marker" v-if="result.report.postTestEducationLevel === 'Tertiary diploma'">
								<div class="arrow"></div>
								<div class="text">Final Post-test</div>
							</div>
							<div class="marker gray right-marker" v-if="result.report.postTestBestEducationLevel === 'Tertiary diploma'">
								<div class="arrow"></div>
								<div class="text">Best Post-test</div>
							</div>
						</div>
						<div class="bullet">
							<div class="label">
								Junior tertiary
							</div>
							<i class="fa fa-circle" v-if="result.report.preTestEducationLevel === 'Junior tertiary'"></i>
							<i class="fa fa-circle-o" v-else></i>
							<div class="marker left-marker" v-if="result.report.preTestEducationLevel === 'Junior tertiary'">
								<div class="arrow"></div>
								<div class="text">Final Pretest</div>
							</div>
							<div class="marker gray left-marker" v-if="result.report.preTestBestEducationLevel === 'Junior tertiary'">
								<div class="arrow"></div>
								<div class="text">Best Pretest</div>
							</div>
							<i class="fa fa-circle" v-if="result.report.postTestEducationLevel === 'Junior tertiary'"></i>
							<i class="fa fa-circle-o" v-else></i>
							<div class="marker right-marker" v-if="result.report.postTestEducationLevel === 'Junior tertiary'">
								<div class="arrow"></div>
								<div class="text">Final Post-test</div>
							</div>
							<div class="marker gray right-marker" v-if="result.report.postTestBestEducationLevel === 'Junior tertiary'">
								<div class="arrow"></div>
								<div class="text">Best Post-test</div>
							</div>
						</div>
						<div class="bullet">
							<div class="label">
								Middle to senior secondary
							</div>
							<i class="fa fa-circle" v-if="result.report.preTestEducationLevel === 'Middle to senior secondary'"></i>
							<i class="fa fa-circle-o" v-else></i>
							<div class="marker left-marker" v-if="result.report.preTestEducationLevel === 'Middle to senior secondary'">
								<div class="arrow"></div>
								<div class="text">Final Pretest</div>
							</div>
							<div class="marker gray left-marker" v-if="result.report.preTestBestEducationLevel === 'Middle to senior secondary'">
								<div class="arrow"></div>
								<div class="text">Best Pretest</div>
							</div>
							<i class="fa fa-circle" v-if="result.report.postTestEducationLevel === 'Middle to senior secondary'"></i>
							<i class="fa fa-circle-o" v-else></i>
							<div class="marker right-marker" v-if="result.report.postTestEducationLevel === 'Middle to senior secondary'">
								<div class="arrow"></div>
								<div class="text">Final Post-test</div>
							</div>
							<div class="marker gray right-marker" v-if="result.report.postTestBestEducationLevel === 'Middle to senior secondary'">
								<div class="arrow"></div>
								<div class="text">Best Post-test</div>
							</div>
						</div>
						<div class="bullet">
							<div class="label">
								Junior to middle secondary
							</div>
							<i class="fa fa-circle" v-if="result.report.preTestEducationLevel === 'Junior to middle secondary'"></i>
							<i class="fa fa-circle-o" v-else></i>
							<div class="marker left-marker" v-if="result.report.preTestEducationLevel === 'Junior to middle secondary'">
								<div class="arrow"></div>
								<div class="text">Final Pretest</div>
							</div>
							<div class="marker gray left-marker" v-if="result.report.preTestBestEducationLevel === 'Junior to middle secondary'">
								<div class="arrow"></div>
								<div class="text">Best Pretest</div>
							</div>
							<i class="fa fa-circle" v-if="result.report.postTestEducationLevel === 'Junior to middle secondary'"></i>
							<i class="fa fa-circle-o" v-else></i>
							<div class="marker right-marker" v-if="result.report.postTestEducationLevel === 'Junior to middle secondary'">
								<div class="arrow"></div>
								<div class="text">Final Post-test</div>
							</div>
							<div class="marker gray right-marker" v-if="result.report.postTestBestEducationLevel === 'Junior to middle secondary'">
								<div class="arrow"></div>
								<div class="text">Best Post-test</div>
							</div>
						</div>
						<div class="bullet">
							<div class="label">
								Senior Primary
							</div>
							<i class="fa fa-circle" v-if="result.report.preTestEducationLevel === 'Senior Primary'"></i>
							<i class="fa fa-circle-o" v-else></i>
							<div class="marker left-marker" v-if="result.report.preTestEducationLevel === 'Senior Primary'">
								<div class="arrow"></div>
								<div class="text">Final Pretest</div>
							</div>
							<div class="marker gray left-marker" v-if="result.report.preTestBestEducationLevel === 'Senior Primary'">
								<div class="arrow"></div>
								<div class="text">Best Pretest</div>
							</div>
							<i class="fa fa-circle" v-if="result.report.postTestEducationLevel === 'Senior Primary'"></i>
							<i class="fa fa-circle-o" v-else></i>
							<div class="marker right-marker" v-if="result.report.postTestEducationLevel === 'Senior Primary'">
								<div class="arrow"></div>
								<div class="text">Final Post-test</div>
							</div>
							<div class="marker gray right-marker" v-if="result.report.postTestBestEducationLevel === 'Senior Primary'">
								<div class="arrow"></div>
								<div class="text">Best Post-test</div>
							</div>
						</div>
						<div class="bullet">
							<div class="label">
								Middle Primary
							</div>
							<i class="fa fa-circle" v-if="result.report.preTestEducationLevel === 'Middle Primary'"></i>
							<i class="fa fa-circle-o" v-else></i>
							<div class="marker left-marker" v-if="result.report.preTestEducationLevel === 'Middle Primary'">
								<div class="arrow"></div>
								<div class="text">Final Pretest</div>
							</div>
							<div class="marker gray left-marker" v-if="result.report.preTestBestEducationLevel === 'Middle Primary'">
								<div class="arrow"></div>
								<div class="text">Best Pretest</div>
							</div>
							<i class="fa fa-circle" v-if="result.report.postTestEducationLevel === 'Middle Primary'"></i>
							<i class="fa fa-circle-o" v-else></i>
							<div class="marker right-marker" v-if="result.report.postTestEducationLevel === 'Middle Primary'">
								<div class="arrow"></div>
								<div class="text">Final Post-test</div>
							</div>
							<div class="marker gray right-marker" v-if="result.report.postTestBestEducationLevel === 'Middle Primary'">
								<div class="arrow"></div>
								<div class="text">Best Post-test</div>
							</div>
						</div>
						<div class="bullet">
							<div class="label">
								Junior Primary
							</div>
							<i class="fa fa-circle" v-if="result.report.preTestEducationLevel === 'Junior Primary'"></i>
							<i class="fa fa-circle-o" v-else></i>
							<div class="marker left-marker" v-if="result.report.preTestEducationLevel === 'Junior Primary'">
								<div class="arrow"></div>
								<div class="text">Final Pretest</div>
							</div>
							<div class="marker gray left-marker" v-if="result.report.preTestBestEducationLevel === 'Junior Primary'">
								<div class="arrow"></div>
								<div class="text">Best Pretest</div>
							</div>
							<i class="fa fa-circle" v-if="result.report.postTestEducationLevel === 'Junior Primary'"></i>
							<i class="fa fa-circle-o" v-else></i>
							<div class="marker right-marker" v-if="result.report.postTestEducationLevel === 'Junior Primary'">
								<div class="arrow"></div>
								<div class="text">Final Post-test</div>
							</div>
							<div class="marker gray right-marker" v-if="result.report.postTestBestEducationLevel === 'Junior Primary'">
								<div class="arrow"></div>
								<div class="text">Best Post-test</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>

		<div v-if="this.showXL && this.hasXtest" class="result">
			<div class="header">
				<img src="../../assets/img/lpcat/header.svg"/>
				<div class="address">
					<div class="spaceafter">
						<img src="../../assets/img/lpcat/logo.svg"/>
						26a Nicolson Street<br/>
						Bailey's Muckleneuk<br/>
						Pretoria<br/>
						0181<br/>
						South Africa
					</div>
					<div>
						Web: <a href="http://www.mminitiatives.com">www.mminitiatives.com</a><br/>
						Email: <a href="mailto:info@mminitiaves.com">info@mminitiaves.com</a>
					</div>
				</div>
			</div>
			<div class="block">			
				<div class="col100-header-dark" v-if="result.report.preTestChartUncertainty">
					LPCAT Chart with uncertainty indicators
				</div>
				<div class="col100-content-light chart-box" v-if="result.report.preTestChartUncertainty">
					<div class="col-inner">
						<span>80</span>
						<span>70</span>
						<span>60</span>
						<span>50</span>
						<span>40</span>
						<span>30</span>
						<span>20</span>
					</div>
					<div class="col-inner-small">
						<GChart
							type="LineChart"
							:settings="{ packages: ['corechart'] }"
							:data="JSON.parse(result.report.preTestChartUncertainty).map(roundValues)"
							:options="this.preTestConfig(200)"
						/>
						<div class="graph-label">Pretest</div>
					</div>
					<div class="col-inner-big">
						<GChart
							type="LineChart"
							:settings="{ packages: ['corechart'] }"
							:data="JSON.parse(result.report.postTestChartUncertainty).map(roundValues)"
							:options="this.postTestConfig(200)"
						/>
						<div class="graph-label">Post-Test</div>
					</div>
				</div>
			</div>
			<div class="block">
				<div class="col100-header-dark" v-if="result.report.preTestChartTime">
					LPCAT Chart with time indicators
				</div>
				<div class="col100-content-light chart-box" v-if="result.report.preTestChartTime">
					<div class="col-inner">
						<span>80</span>
						<span>70</span>
						<span>60</span>
						<span>50</span>
						<span>40</span>
						<span>30</span>
						<span>20</span>
					</div>
					<div class="col-inner-small">
						<GChart
							type="LineChart"
							:settings="{ packages: ['corechart'] }"
							:data="JSON.parse(result.report.preTestChartTime).map(roundValues)"
							:options="this.preTestConfig(200)"
						/>
						<div class="graph-label">Pretest</div>
					</div>
					<div class="col-inner-big">
						<GChart
							type="LineChart"
							:settings="{ packages: ['corechart'] }"
							:data="JSON.parse(result.report.postTestChartTime).map(roundValues)"
							:options="this.postTestConfig(200)"
						/>
						<div class="graph-label">Post-Test</div>
					</div>
				</div>
			</div>
			<div class="block">
				<div class="col100-header-dark">
					Xtesteex
				</div>
				<div class="col100-content-light">
					<div class="raw-data">
						<table>
							<tr>
								<th><b>Phase</b></th>
								<th><b>Count</b></th>
								<th><b>Item</b></th>
								<th><b>Answer</b></th>
								<th><b>Given Answer</b></th>
								<th><b>Theta</b></th>
								<th><b>SE</b></th>
							</tr>
							<tr v-for="response in phase1Data" :key="response[3]">
								<td>1</td>
								<td>{{response[3]}}</td>
								<td>{{response[4]}}</td>
								<td>{{response[6]}}</td>
								<td>{{response[5]}}</td>
								<td>{{response[0]}}</td>
								<td>{{response[1]}}</td>
							</tr>
							<tr v-for="response in phase2Data" :key="response[3]">
								<td>2</td>
								<td>{{response[3]}}</td>
								<td>{{response[4]}}</td>
								<td>{{response[6]}}</td>
								<td>{{response[5]}}</td>
								<td>{{response[0]}}</td>
								<td>{{response[1]}}</td>
							</tr>
						</table>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { GChart } from 'vue-google-charts'
import moment from 'moment/src/moment'

export default {
	name: 'LPCATResult',
	components: {
		GChart
	},
	props: {
		showXL: Boolean,
		result: Object,
	},
	data () {
		return {}
	},
	computed: {
		hasXtest: function () { return JSON.parse(this.result.report.xtesteex).length > 0},
		difference: function () {
			let roundedPreTestScore = Math.round(this.result.report.preTestScore)
			let roundedPostTestScore = Math.round(this.result.report.postTestScore)
			return roundedPostTestScore - roundedPreTestScore
		},
		composite: function () {
			let diff = this.difference
			let roundedPreTestScore = Math.round(this.result.report.preTestScore)

			if (diff <= 0) return roundedPreTestScore

			let composite = Math.round(roundedPreTestScore + ((diff / (80 - roundedPreTestScore)) * diff))

			return composite
		},
		phase1Data: function () {
			let responses = JSON.parse(this.result.report.xtesteex)
			return responses.filter(response => response[2] === 1)
		},
		phase2Data: function () {
			let responses = JSON.parse(this.result.report.xtesteex)
			return responses.filter(response => response[2] === 2)
		}
	},
	methods: {
		roundValues (keyValue) {
			let value = keyValue[1]
			if (isNaN(value)) return keyValue
			keyValue[1] = Math.round(value)
			return keyValue
		},
		printDocument () {
			window.print();
		},
		downloadDocument () {
			window.print();
		},		
		createCsv () {
			const demographics = { ... this.result };
			const report = { ... this.result.report };			
			const rows = [
				["firstname",
				"lastname",
				"idnumber",
				"country",
				"age",
				"gender",
				"compositeScore",
				"differenceScore",
				"educationlevel",
				"languageScore",
				"preTestBestEducationLevel","preTestBestScore","preTestEducationLevel","preTestPercentile","preTestScore","preTestStanine",
				"postTestBestEducationLevel","postTestBestScore","postTestEducationLevel","postTestPercentile","postTestScore","postTestStanine",
				"created","processed"],
				[demographics.firstname,
				demographics.lastname,
				!demographics.idnumber ? '' : demographics.idnumber,
				demographics.country,
				demographics.age,
				demographics.gender,
				this.composite,
				this.difference,
				demographics.educationLevel,
				demographics.languageScore,
				report.preTestBestEducationLevel,report.preTestBestScore,report.preTestEducationLevel,report.preTestPercentile,report.preTestScore,report.preTestStanine,
				report.postTestBestEducationLevel,report.postTestBestScore,report.postTestEducationLevel,report.postTestPercentile,report.postTestScore,report.postTestStanine,
				this.localTime(demographics.created),this.localTime(demographics.processed)]
			]

			let csvContent = "data:text/csv;charset=utf-8," 
				+ rows.map(e => e.join(",")).join("\n");

			var encodedUri = encodeURI(csvContent);
			var link = document.createElement("a");
			link.setAttribute("href", encodedUri);
			link.setAttribute("download", `${demographics.firstname}_${demographics.lastname}csv`);
			document.body.appendChild(link); // Required for FF
			link.click(); 			
		},
		localTime (created) {
			return moment(created).format("DD MMM YYYY HH:mm")
		},
		closeResult () {
			this.$emit('close-result');
		},
		testLanguage (language) {
			if (language === 'NL') {
				return 'Dutch'
			} else if (language === 'EN') {
				return 'English'
			} else 
				return language;
		},
		preTestConfig: (height) => ({
			height,
			chartArea: {
				width: '95%',
				height: '95%'
			},
			hAxis: {
				gridlines: {
					count: -1,
					color: '#f3f3f3'
				},
				baselineColor: '#f1f1f1'
			},
			vAxis: {
				title: 'T-Score',
				viewWindow: {
					min: 0,
					max: 100
				},
				gridlines: {
					count: -1,
					color: '#f3f3f3'
				},
				baselineColor: '#f1f1f1'
			},
			legend: {position: 'none'},
			pointSize: 5,
			lineWidth: 1,
			colors: ['#75B441', '#ddd'],
			seriesType: 'line',
			series: {
				1: { type: 'bars' }
			}
		}),
		postTestConfig: (height) => ({
			height,
			chartArea: {
				width: '96%',
				height: '95%'
			},
			hAxis: {
				gridlines: {
					count: -1,
					color: '#f3f3f3'
				},
				baselineColor: '#f1f1f1'
			},
			vAxis: {
				title: 'T-Score',
				viewWindow: {
					min: 0,
					max: 100
				},
				gridlines: {
					count: -1,
					color: '#f3f3f3'
				},
				baselineColor: '#f1f1f1'
			},
			legend: {position: 'none'},
			pointSize: 5,
			lineWidth: 1,
			colors: ['#75B441', '#ddd'],
			seriesType: 'line',
			series: {
				1: { type: 'bars' }
			}
		})
	}
}
</script>

<style scoped>
	* {
			-webkit-box-sizing: border-box;
			-moz-box-sizing: border-box;
			box-sizing: border-box;
	}

	.container {
		position: fixed;
		overflow: auto;
		top: 79px;
		bottom: 0px;
		left: 0px;
		right: 0px;
		background-color: rgba(0, 0, 0, 0.8);
		cursor: default;
		overflow: auto;
	}

	.container::-webkit-scrollbar {
		width: 10px;
		background-color: #1A1E23;
	}

	.container::-webkit-scrollbar-thumb {
		background-color: #576475;
		border-radius: 5px;
		border: 2px solid #1A1E23;
		cursor: pointer;
	}

	.menu {
		padding: 20px;
		position: fixed;
		top: 0;
		left: 0;
		right: 0;
		width: 100%;
		background-color: #343434;
		-webkit-box-shadow: 0px 2px 4px 0px rgba(0,0,0,0.4);
		-moz-box-shadow: 0px 2px 4px 0px rgba(0,0,0,0.4);
		box-shadow: 0px 2px 4px 0px rgba(0,0,0,0.4);
	}

	.result {
		zoom: 120%;
		width: 695px;
		height: 1048px;
		padding: 20px;
		margin-top: 30px;
		margin-left: auto;
		margin-right: auto;
		background-color: #fcfcfc;
		font: 12px/20px "Helvetica Neue", Arial, sans-serif;
		-webkit-box-shadow: -2px 6px 56px -8px rgba(0,0,0,0.75);
		-moz-box-shadow: -2px 6px 56px -8px rgba(0,0,0,0.75);
		box-shadow: -2px 6px 56px -8px rgba(0,0,0,0.75);
		border-radius: 2px;
	}

	.header {
		position: relative;
		display: block;
		height: 128px;
		width: 100%;
		margin: 0 auto 40px auto;
	}

	.header > img {
		position: absolute;
		bottom: 0px;
	}

	.header .address {
		font-size: 11px;
		line-height: 13px;
		width: 162px;
		padding: 0px;
		float: right;
	}

	.header .address img {
		margin-bottom: 8px;
	}

	.header .address .spaceafter {
		margin-bottom: 7px;
	}

	a {
		text-decoration: none;
		color: #444;
	}

	.col50-header-dark {
		float: left;
		width: 50%;
		font-weight: bold;
		color: white;
		background-color: #89C359;
		border: 1px solid white;
		padding: 2px 4px;
		font-size: 10px;
	}

	.col50-header-light {
		float: left;
		width: 50%;
		font-weight: bold;
		color: white;
		background-color: #B0D691;
		border: 1px solid white;
		padding: 2px 4px;
		font-size: 10px;
	}

	.col100-header-light {
		width: 100%;
		font-weight: bold;
		color: white;
		background-color: #B0D691;
		border: 1px solid white;
		padding: 2px 4px;
		font-size: 10px;
	}

	.col100-header-dark {
		width: 100%;
		font-weight: bold;
		color: white;
		background-color: #89C359;
		border: 1px solid white;
		padding: 2px 4px;
		overflow: auto;
		font-size: 10px;
	}

	.col50-content-dark {
		float: left;
		width: 50%;
		font-weight: normal;
		color: #444444;
		background-color: #e5e5e5;
		border: 1px solid white;
		padding: 2px 4px;
		font-size: 10px;
	}

	.col50-content-light {
		float: left;
		width: 50%;
		font-weight: normal;
		color: #444444;
		background-color: #f2f2f2;
		border: 1px solid white;
		padding: 2px 4px;
		font-size: 10px;
	}

	.col50-content-gray {
		float: left;
		width: 50%;
		font-weight: bold;
		color: #444444;
		background-color: #e5e5e5;
		border: 1px solid white;
		padding: 2px 4px;
		font-size: 10px;
	}

	.col50 {
			width: 50%;
			font-size: 10px;
	}

	.col25-content-gray {
		float: left;
		width: 25%;
		font-weight: bold;
		color: #444444;
		background-color: #e5e5e5;
		border: 1px solid white;
		padding: 2px 4px;
		font-size: 10px;
	}

	.col25-content-light {
		float: left;
		width: 25%;
		font-weight: normal;
		color: #444444;
		background-color: #f2f2f2;
		border: 1px solid white;
		padding: 2px 4px;
		font-size: 10px;
	}

	.col75-content-light {
		float: left;
		width: 75%;
		font-weight: normal;
		color: #444444;
		background-color: #f2f2f2;
		border: 1px solid white;
		padding: 2px 4px;
		font-size: 10px;
	}

	.col75-content-gray {
		float: left;
		width: 75%;
		font-weight: bold;
		color: #444444;
		background-color: #e5e5e5;
		border: 1px solid white;
		padding: 2px 4px;
		font-size: 10px;
	}

	.col50-content-dark b
	{
		display: inline-block;
		width: 125px;
	}

	.col50-content-light b
	{
		display: inline-block;
		width: 120px;
		margin-right: 10px;
	}

	.col50-content-light span {
		display: inline-block;
		width: 70px;
	}

	.col100-content-light {
		width: 100%;
		font-weight: normal;
		color: #444444;
		background-color: #f2f2f2;
		border: 1px solid white;
		padding: 2px 4px;
	}

	.col100-content-dark {
		width: 100%;
		font-weight: normal;
		color: #444444;
		background-color: #e5e5e5;
		border: 1px solid white;
		padding: 2px 4px;
	}

	.col25-header-light, .col25-header-dark,
	.col50-header-light, .col50-header-dark,
	.col100-header-light, .col100-header-dark,
	.col25-content-gray, .col25-content-light,
	.col50-content-gray, .col50-content-light,
	.col75-content-light {
		height: 24px;
	}

	.chart-box {
		height: 202px;
		display: block;
	}

	.col-inner {
		position: relative;
		float: left;
		width: 3%;
		padding-top: 23px;
		font-weight: normal;
		background-color: white;
		height: 194px;
		font-size: 10px;		
	}

	.col-inner.col-inner-xl {
		height: 380px;
		padding-top: 72px !important;
	}

	.col-inner span {
		display: block;
		white-space: nowrap;
		margin-left: 4px;
		margin-bottom: 1px;
	}

	.col-inner.col-inner-xl span {
		margin-bottom: 16px;
	}

	.col-inner-small {
		position: relative;
		float: left;
		width: 39%;
		font-weight: normal;
		overflow: hidden;
		height: 194px;
	}

	.col-inner-small > div {
		margin-top: -6px;
	}

	.col-inner-big {
		padding-left: 6px;
		position: relative;
		float: left;
		width: 58%;
		font-weight: normal;
		overflow: hidden;
		height: 194px;
	}

	.col-inner-big > div {
		margin-top: -6px;
	}

	.divider {
		margin-top: 4px;
		padding-top: 4px;
		height: 1px;
		width: 100%;
		border-top: 1px solid #fcfcfc;
		border-bottom: 1px solid transparent;
	}

	.graph-label {
		position: absolute;
		left: 20px;
		top: 10px;
		color: #444;
		background-color: rgba(0, 0, 0, 0.1);
		padding: 1px 4px;
		border-radius: 4px;
		font-size: 10px;
		font-weight: bold;
	}

	.col66-inner .graph-label {
		left: 18px;
	}

	.bullet-wrapper {
		padding: 0px 4px;
		background-color: white;
	}

	.bullet {
		position: relative;
		padding: 0px;
		margin: 0px 4px;
		height: 24px;
		line-height: 24px;
		border-top: 1px solid #f3f3f3;
	}

	.bullet:first-child {
		border-top: none;
	}

	.bullet .label {
		float: left;
		width: 40%;
	}

	.bullet i {
		color: #75B441;
		margin-right: 27%;
		font-size: 12px;
		line-height: 24px;
	}

	.bullet .marker {
		position: absolute;
		width: 80px;
		height: 16px;
		line-height: 16px;
		top: 4px;
		background-color: #B0D691;
		border-top-right-radius: 4px;
		border-bottom-right-radius: 4px;
		z-index: 99;		
	}

	.bullet .marker.gray {
		position: absolute;
		color: #aaa;
		background-color: #eee;
		z-index: 0;
	}

	.bullet .marker .text {
		position: absolute;
		font-weight: bold;
		height: 16px;
		line-height: 16px;
		text-align: center;
		left: 0px;
		display: inline-block;
		z-index: 99;
		font-size: 10px;
	}

	.bullet .marker .arrow {
		position: absolute;
		width: 12px;
		height: 12px;
		top: 2px;
		left: -7px;
		transform: rotate(45deg);
		background-color: #B0D691;
		border-radius: 1px;
	}

	.bullet .marker.gray .arrow {
		background-color: #eee;
	}

	.bullet .left-marker {
		left: 46%;
	}

	.bullet .right-marker {
		left: 75%;
	}

	.right {
		float: right;
	}

	.heightx2 {
		height: 60px;
	}

	button {
		margin-right: 16px;
		padding: 11px 12px;
		color: white;
		background-color: #3e3e3e;
		font-size: 14px;
		border-radius: 2px;
		border: none;
		cursor: pointer;
		-webkit-box-shadow: 0px 0px 2px 0px rgba(0,0,0,0.2);
		-moz-box-shadow: 0px 0px 2px 0px rgba(0,0,0,0.2);
		box-shadow: 0px 0px 2px 0px rgba(0,0,0,0.2);
	}

	button:hover {
		background-color: #4e4e4e;
	}

	button i {
		padding-left: 12px;
		margin-left: 8px;
		border-left: 1px solid #5e5e5e;
		font-size: 16px;
	}

	.blue {
		background-color: #00a1cb;
	}

	.blue:hover {
		background-color: #00B4E0;
	}

	.blue i {
		border-left: 1px solid #85E7FF;
	}

	.raw-data {
		font-size: 10px;
	}

	table {
		width: 100%;
		border-collapse: collapse;
        border-spacing: 0px;
	}

	table tr {
		line-height: 12px;
	}

	tr > td {
		width: 15%;
		padding: 0px;
		margin: 0px;
		border-top: 1px solid white;
	}

	@page{
		margin: 0px;
	}

	@media print {
		.header {
			display: block;
		}

		.container {
			position: absolute;
			top: 0px;
			bottom: auto;
			height: auto;
			overflow: auto;
			padding: 0px;
			margin: 0px;
			background-color: white;
			opacity: 1;
			margin: -1px;
		}

		.menu {
			display: none;
		}
		
		.result {
			width: 210mm;
			height: 296mm;
			padding: 20mm;

			zoom: 100%;
			margin: 0px;
			border: none;
			box-shadow: none;
			background-color: white;
			opacity: 1;
		}

		.page-break {
			page-break-after: always;
		}

		.block {
			border: 1px solid gray;
			padding: 3px;
			border-radius: 3px;
			overflow: auto;
			margin-bottom: 3px;
		}

		.col25-content-gray {
			color: black;
			background-color: #f3f3f3;			
		}

		.col25-header-dark, .col50-header-dark, .col100-header-dark, .col100-header-light, .col50-header-light {
			color: black;
			background-color: #f3f3f3;
			border-bottom: 1px solid lightgrey;
		}

		.col50-header-light {
			border-left: 1px solid lightgrey;
		}

		.col25-content-dark, .col50-content-dark, .col100-content-dark {
			background-color: #fafafa;
		}

		.col25-content-light, .col75-content-light, .col50-content-light {
			background-color: #fcfcfc;
			height: 24px;
		}

		.col100-content-light {
			background-color: #fcfcfc;
		}

		.divider {
			border-top: 1px solid #f3f3f3;
			border-bottom: 1px solid transparent;
		}

		tr > td {
			border-top: 1px solid #f3f3f3;
		}
	}
</style>
