<template>
  <div class="container">
    <video autoplay="true" loop="true" poster="../../assets/img/portal/lawn.jpg">
      <source src="../../assets/img/portal/lawn.mp4" type="video/mp4">
    </video>

    <div class="login">
      <img class="header" src="../../assets/img/mmlogo.svg" />
      <div class="sub">
        Type your pin code to unlock the screen. If you don't know what to do
        press <span style="color:yellow"><b>cancel</b></span>.
      </div>
      <div>
        <div class="pin first" v-bind:class="{ focus: focussed(0), answered: answered(0) }">
          <i class="fa fa-circle"></i>
        </div>
        <div class="pin" v-bind:class="{ focus: focussed(1), answered: answered(1) }" >
          <i class="fa fa-circle"></i>
        </div>
        <div class="pin" v-bind:class="{ focus: focussed(2), answered: answered(2) }" >
          <i class="fa fa-circle"></i>
        </div>
        <div class="pin" v-bind:class="{ focus: focussed(3), answered: answered(3) }" >
          <i class="fa fa-circle"></i>
        </div>
        <div class="pin" v-bind:class="{ focus: focussed(4), answered: answered(4) }" >
          <i class="fa fa-circle"></i>
        </div>
      </div>
      <button @click='cancel' class="cancel">Cancel</button>
      <button :disabled='disabled()' @click='unlock'>Unlock</button>
      <input id="pincode" ref="pincode" v-model='pin' class="recorder" maxlength="5" @keyup='recordKey' autofocus autocomplete="off"/>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Pin',
  props: {
    isPINVisibile: Boolean
  },
  data () {
    return {
      pin1: '',
      pin2: '',
      pin3: '',
      pin4: '',
      pin5: '',
      pin: '',
      blocked: 0,
    }
  },
  created() {
    setTimeout(function () {
      document.getElementById('pincode').focus()
    }, 500);
  },
  methods: {
    unlock() {

      this.$refs.pincode.focus()

      if (this.blocked > 2) {
        this.$emit('log-user-out')
        return
      }

      if (this.pin !== '12345') {
        this.blocked++
        this.pin = ''
        this.pin1 = ''
        this.pin2 = ''
        this.pin3 = ''
        this.pin4 = ''
        this.pin5 = ''
        return
      }

      this.$emit('pin-unlock')
    },
    cancel() {
      this.$emit('pin-cancel')
    },
    saveValue(event) {
      event.target.nextElementSibling.focus()
    },
    focussed(pos) {
      switch (pos) {
        case 0: return this.pin1 === '';
        case 1: return this.pin1 !== '' && this.pin2 === '';
        case 2: return this.pin2 !== '' && this.pin3 === '';
        case 3: return this.pin3 !== '' && this.pin4 === '';
        case 4: return this.pin4 !== '' && this.pin5 === '';
      }
    },
    answered(pos) {
      switch (pos) {
        case 0: return this.pin1 !== '';
        case 1: return this.pin2 !== '';
        case 2: return this.pin3 !== '';
        case 3: return this.pin4 !== '';
        case 4: return this.pin5 !== '';
      }
    },
    recordKey(event) {

      // Backspace
      if (event.keyCode === 8) {
        if (this.pin5 !== '') {
          this.pin5 = ''
        }
        else if (this.pin4 !== '') {
          this.pin4 = ''
        }
        else if (this.pin3 !== '') {
          this.pin3 = ''
        }
        else if (this.pin2 !== '') {
          this.pin2 = ''
        }
        else if (this.pin1 !== '') {
          this.pin1 = ''
        }
      }

      // escape
      if (event.keyCode === 27) {
        this.pin1 = ''
        this.pin2 = ''
        this.pin3 = ''
        this.pin4 = ''
        this.pin5 = ''
        return
      }

      // submit
      if (event.keyCode === 13 && !this.disabled()) {
        this.unlock()
        return
      }

      let key = Number(event.key)
      if (isNaN(key) || event.key === null) {
        return
      }

      if (this.pin1 === '') {
        this.pin1 = key
      }
      else if (this.pin2 === '') {
        this.pin2 = key
      }
      else if (this.pin3 === '') {
        this.pin3 = key
      }
      else if (this.pin4 === '') {
        this.pin4 = key
      }
      else if (this.pin5 === '') {
        this.pin5 = key
        setTimeout(() => {
            this.unlock()
        }, 100)
      }
    },
    disabled() {
      return this.pin5 === '';
    }
  }
}
</script>

<style scoped>

  .pin.first {
    margin-left: 0px;
  }

  .pin {
    float: left;
    width: 58px;
    height: 58px;
    padding: 16px 8px 0px 8px;
    border-radius: 8px;
    margin-top: 24px;
    margin-left: 7px;
    font-size: 18px;
    vertical-align: middle;
    text-align: center;
    text-shadow: none;
    -webkit-box-shadow: inset 0px 0px 5px 1px rgba(0,0,0,0.3);
    -moz-box-shadow: inset 0px 0px 5px 1px rgba(0,0,0,0.3);
    box-shadow: inset 0px 0px 5px 1px rgba(0,0,0,0.3);
    background-color: white;
    color: transparent;
    border: 2px solid #3e3e3e;
  }

  .pin.focus {
    border: 2px solid #61ae24;
    background-color: #E9F8DD;
  }

  .pin.answered {
    background-color: #61ae24;
    color: white;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;

  }

  .container {
    width: 100%;
    height: 100%;
    overflow: hidden;
    background: url(../../assets/img/portal/lawn.jpg) no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;

    color: #c3c3c3;
    font: 16px/24px "Helvetica Neue", Arial, Helvetica, Geneva, sans-serif;
    text-shadow: 0 1px 2px rgba(0, 0, 0, 0.8);
    font-weight: 400;
  }

  .sub {
    margin-top: 20px;
    margin-bottom: 16px;
  }

  .recorder {
    opacity: 0;
    position: absolute;
    left: 40px;
    top: 180px;
    width: 320px;
    height: 60px;
    cursor: pointer;
  }

  video {
    width: 100%;
    min-height: 100%;
    object-fit: cover;
    padding: 0px;
    margin: 0px;
  }

  input {
    width: 100%;
    padding: 12px;
    margin-top: 8px;
    border-radius: 4px;
    border: 2px solid #3e3e3e;
    font: 16px;
    color: transparent;
    text-shadow: 0 0 0 #61ae24;
    text-align: center;
  }

  input:focus {
    border: 2px solid #87D945;
  }

  button.cancel {
    position: absolute;
    padding: 12px 16px;
    bottom: 40px;
    left: 40px;
    color: white;
    background-color: #5e5e5e;
    font-size: 14px;
    border-radius: 4px;
    border: none;
    cursor: pointer;
  }

  button.cancel:hover {
    background-color: #6e6e6e;
  }

  button {
    position: absolute;
    padding: 12px 16px;
    bottom: 40px;
    right: 40px;
    color: white;
    background-color: #61ae24;
    font-size: 14px;
    border-radius: 4px;
    border: none;
    cursor: pointer;
    -webkit-box-shadow: 0px 0px 2px 0px rgba(0,0,0,0.4);
    -moz-box-shadow: 0px 0px 2px 0px rgba(0,0,0,0.4);
    box-shadow: 0px 0px 2px 0px rgba(0,0,0,0.4);
  }

  button:hover {
    background-color: #87D945;
  }

  button:disabled {
      background-color: #667D54;
  }

  .header {
      margin-bottom: 12px;
  }

  .title {
    font-size: 18px;
    padding-top: 12px;
    margin-bottom: 16px;
  }

  .label {
    margin-top: 12px;
  }

  .login {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 400px;
    height: 400px;
    padding: 40px;
    background-color: #3e3e3e;
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.15) 0px 30px 40px 10px;
  }
</style>
