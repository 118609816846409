<template>
  <div class="folder">
    <div class="folder-name" :class="{ selected: isSelected() }">
      <div class="menu">
      </div>
      <div class="folder-name-container" @click="toggleFolder" >
        <i class="fa fa-user"></i>
        {{user.name}}
      </div>
    </div>
    <div v-if="open" class="children">
      <Folder 
        :folder="folder" 
        :folders="folders" 
        :id="folderId" 
        :key="folderId" 
        :selectedFolderId="selectedFolderId"
        :isTrash="false"
        :isShared="isShared"
        :isLinked="isLinked"
        :isVoucher="false"
        v-for="(folder, folderId) in directChildFolders"
        @select-folder="selectFolder"/>
      </div>
  </div>
</template>

<script>

import firebase from "../../../firebase";
import Folder from './Folder.vue'

const firestore = firebase.firestore();


export default {
  name: 'User',
  components: {
    Folder,
  },
  data: function () {
    return {
      selected: false,
      open: false,
      folder: {
        parent: '',
      },
      folders: [],
    }
  },
  props: {
    selectedFolderId: String,
    isShared: Boolean,
    isLinked: Boolean,
    user: Object
  },
  computed: {
    directChildFolders: function () {
      let children = {}
      let parent = ''
      let folders = this.folders
      Object.keys(folders).forEach(function(folderId) {
          let folder = folders[folderId]
          if (folder.parent === parent) {
            children[folderId] = folder
          }
      });
      return children
    }
  },
  methods: {
    folderName () {
      return this.user.name
    },
    selectFolder (id, folderName, isTrash, isShared, isLinked, isVoucher) {
      this.$emit('select-folder', id, folderName, isTrash, isShared, isLinked, isVoucher, this.user.email, this.user.company)

      if (this.folders.length === 0) {
        this.loadFolders();
      }
    },    
    toggleFolder () {
      let caret = '<i class="fa fa-angle-double-right caret"></i>'
      let folderName = typeof this.$parent.folderName === "function"
          ? caret + this.$parent.folderName() + caret + this.folderName()
          : caret + this.user.name

      this.selectFolder(this.user.email, folderName, false, true, false, false)

      if ((this.isSelected() && this.open) || !this.open) {
        this.open = !this.open
      }
    },
    isSelected () {
      return this.user.email === this.selectedFolderId
    },
    loadFolders () {
        firestore
        .collection("companies")
        .doc(this.user.company)
        .collection("users")
        .doc(this.user.email)
        .collection("folders")
        .onSnapshot((querySnapshot) => {
          const folders = {};
          querySnapshot.forEach((doc) => { 
            folders[doc.id] = doc.data()
          });
          this.folders = folders;
        });
    }
  },
}
</script>

<style scoped>
  .folder {
    cursor: pointer;
    color: #c3c3c3;
    min-height: 29px;
    line-height: 29px;
  }

  .folder-name {
    padding-bottom: 8px;
    margin-bottom: 8px;
    border-bottom: 1px solid #444;
  }

  .folder-name:hover {
    color: white;
  }

  .folder-name i {
    width: 20px;
  }

  .folder-name-container {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .selected {
    color: yellow !important;
  }

  .selected  .menu {
    opacity: 1;
  }

  .children {
    margin-left: 28px;
  }

  .menu {
    display: inline-block;
    opacity: 0;
    float: right;
    margin-left: 16px;
    margin-right: 0px;
    margin-top: -2px;
    padding: 2px 8px;
    color: white;
    background-color: #3e3e3e;
    font-size: 14px;
    border-radius: 2px;
    border: none;
    cursor: pointer;
    -webkit-box-shadow: 0px 0px 2px 0px rgba(0,0,0,0.2);
    -moz-box-shadow: 0px 0px 2px 0px rgba(0,0,0,0.2);
    box-shadow: 0px 0px 2px 0px rgba(0,0,0,0.2);
    transition: opacity 0.3s;
  }

  .menu a:first-child {
    padding-left: 0px;
    margin-left: 4px;
    border-left: none;
    font-size: 16px;
  }

  .menu a {
    padding-left: 12px;
    margin-left: 8px;
    border-left: 1px solid #5e5e5e;
    font-size: 16px;
  }

  .menu a:hover {
    color: yellow;
  }
</style>
